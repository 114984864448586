import { IResourceComponentsProps, useList } from "@refinedev/core";

import {
    DateField,
    List,
    TagField,
    useModal,
    useTable,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Modal, Space, Table, Tooltip, Typography, Popover, Button, Row, Col, Spin} from 'antd';
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
    ILogPosition,
    IStatusWareHouse,
    IWareHousePosition,
} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import {SocketContext} from '../../contexts/socket';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import {API_URL} from '../../constants';
import {UtilityHelper} from '../../helper/utility';
const {Title} = Typography;
export const DashboardList: React.FC<IResourceComponentsProps> = () => {
    const utilityHelper = UtilityHelper(API_URL + '/api');
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const socket = useContext(SocketContext);
    const [lis, setLis] = useState('');
    const [role, setRole] = useState('');

    const {tableProps, searchFormProps, tableQueryResult} = useTable<IStatusWareHouse>({
        resource: "custom/dashboardBox",
        meta: {populate: 'device,deviceType,seller'},

        pagination: {
            pageSize: 25,
            mode: "server"
        },

        filters: {
            permanent: [
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id
                },
                {
                    field: 'device][device_type][isMagazzino]',
                    operator: 'eq',
                    value: true
                }
            ]
        },
        sorters: {
            permanent: [
                {
                    field: 'device.description',
                    order: 'asc'
                },
                {
                    field: 'module',
                    order: 'asc'
                },
            ]
        }
    });

    const {tableProps: logTableProps, tableQueryResult: dataLog} = useTable<ILogPosition>({
        resource: "log-positions",
        meta: {populate: 'stock,seller, stock.product, stock.product.category'},

        pagination: {
            pageSize: 15,
            mode: "server"
        },

        filters: {
            permanent: [
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id
                }
            ]
        },
        sorters: {
            permanent: [
                {
                    field: 'createdAt',
                    order: 'desc'
                }
            ]
        }
    });

    useEffect(() => {
        utilityHelper.getListino().then( (res: any) => {
            setLis(res);
        });
    }, [])
    const [position, setPosition] = useState<string>();
    const [product, setProduct] = useState<any>('');
    const { show, modalProps, close } = useModal();

    const {data: checkBox, refetch: getCheckBox}  = useList<any>({
        resource: "custom/checkBox",
        queryOptions: {
            enabled: false
        },
    });

    const [updateLog, setUpdateLog] = useState<any>([]);
    const [loadLog, setLoadLog] = useState<boolean>(true);
    const [updateEsp, setUpdateEsp] = useState<any>([]);
    const [loadLogEsp, setLoadEsp] = useState<boolean>(true);

    useEffect(() => {
        utilityHelper.permission().then( (res: any) => {
            setRole(res);
            socket.on("updateData", (data: any, error: any) => {
                tableQueryResult.refetch();
                getCheckBox();
                // console.log(data);
            });
            if (res === 'admin') {
                socket.on("checkEsp32", (data: any, error: any) => {
                    // console.log(data.log);
                    setLoadEsp(false);
                    setUpdateEsp((current: any) => [...current, data?.log]);
                    // console.log(data);
                });
                socket.on("checkUpdate", (data: any, error: any) => {
                    setLoadLog(false);
                    setUpdateLog((current: any) => [...current, data?.log]);
                });
            }

            return () => {
                socket.off('updateData');
                if (res === 'admin') {
                    socket.off('checkEsp32');
                    socket.off('checkUpdate');
                }
            };
        })
    }, []);

    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     // console.log(isSuccess, isLoading, isFetching, isFetched, isRefetching)
    //     if (isSuccess && !isLoading && !isFetching && isFetched && !isRefetching) {
    //         setProduct(data?.data?.[0].stock);
    //     }
    // });

    const viewProduct = (record: any, value: any, column: string) => {
        // console.log(record, value, column);
        // if (value) {
        //     setPosition(column);
        //     refetch().then( result => {
        //         setLoading(true);
        //     })
        // }

    }

    const modalClose = () => {
        close();
    }

    const [showDashboard, setShowDashboard] = useState(false);
    const [showUpateLog, setShowUpdateLog] = useState(false);
    const [showUpateEsp, setShowUpdateEsp] = useState(false);
    const [showLog, setShowLog] = useState(false);
    const [dataExp, setDataExp] = useState<any>([]);
    const exportMovement = (event: any, done: any) => {
        dataLog.data?.data.forEach( (v: any) => {
            console.log(v);
            setDataExp((current: any) => [...current, {
                codice: v.stock.product.codice,
                descrizione: v.stock.product.description,
                posizione: v.position + '_',
                data: dayjs(v.dataUpdate).format('DD-MM-YYYY HH:mm'),
                azione: v.isPresent ? 'Inserito' : 'Prelevato'
            }])
        })
        setTimeout(() => {
            done(true);
        }, 1000)
    }

    const showBox = () => {
        setShowDashboard(!showDashboard)
    }
    return (
        <List title={''}>
            <Row gutter={24}>
                <Col span={24}>
                    <Title style={{color: 'greenyellow'}} level={2}>Ultimo Aggiornamento Listino : <DateField style={{fontSize: 28}} value={lis} format={'DD-MM-YYYY'} /></Title>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Space>
                        <Button type={'primary'} style={{backgroundColor: !showLog ? 'red' : 'green'}} onClick={() => setShowLog(!showLog)}>Mostra Log</Button>
                        <Button type={'primary'} style={{backgroundColor: !showDashboard ? 'red' : 'green'}} onClick={() => showBox()}>Mostra Magazzino</Button>
                        <Button hidden={role !== 'admin'} type={'primary'} style={{backgroundColor: !showUpateLog ? 'red' : 'green'}} onClick={() => setShowUpdateLog(!showUpateLog)}>Mostra Log Device</Button>
                        <Button hidden={role !== 'admin'} type={'primary'} style={{backgroundColor: !showUpateEsp ? 'red' : 'green'}} onClick={() => setShowUpdateEsp(!showUpateEsp)}>Mostra Log Box</Button>
                    </Space>
                </Col>
            </Row>
            <hr />
            <div hidden={!showLog}>
                <Table {...logTableProps}
                    size={'small'}
                    footer={(value) => (<CSVLink
                        data={dataExp}
                        separator={';'}
                        asyncOnClick={true}
                        filename={'Log_Operazioni_' + dayjs().format('DD_MM_YYYY') + '.csv'}
                        onClick={exportMovement}
                    >
                        <Button icon={<Icons.FileExcelOutlined />}></Button>
                    </CSVLink>)}
                >
                    <Table.Column
                        dataIndex={'stock'}
                        title={'Prodotto'}
                        key={'prodotto.codice'}
                        render={(value) => (
                            value.product?.codice
                        )}
                    />
                    <Table.Column
                        dataIndex={'stock'}
                        title={'Prodotto'}
                        key={'prodotto'}
                        render={(value) => (
                            value.product?.description
                        )}
                    />
                    <Table.Column
                        dataIndex={'position'}
                        title={'Posizione'}
                        key={'position'}
                        />
                    <Table.Column
                        dataIndex={'dataUpdate'}
                        title={'Data Operazione'}
                        key={'dataUpdate'}
                        render={(value) => (
                            <DateField value={value} format={'DD-MM-YYYY - HH:mm:ss'} />
                        )}
                    />
                    <Table.Column
                        dataIndex={'isPresent'}
                        title={'Azione'}
                        key={'isPresent'}
                        render={(value) => (
                            <TagField value={value ? 'Inserito' : 'Prelevato'} style={{ color: value ? 'green' : 'red'}} />
                        )}
                    />
                </Table>
            </div>
            <div hidden={!showDashboard}>
                <Table size="small" {...tableProps} rowKey="id"
                       sticky={true}
                       // scroll={{y: window.innerHeight - 393 + 'px'}}
                >
                    <Table.Column
                        dataIndex="description"
                        key="description"
                        title="Dispositivo"
                        align={'center'}
                        width={50}
                        // render={(value) => String.fromCharCode(value + 65)}
                        render={(value) => <TagField color={'orange'} value={value} />}
                    />
                    <Table.Column
                        dataIndex="device"
                        key="device"
                        title="Master"
                        align={'center'}
                        width={20}
                        // render={(value) => String.fromCharCode(value + 65)}
                        render={(value) => <TagField color={'blue'} value={value} />}
                    />
                    <Table.Column
                        dataIndex="module"
                        key="module"
                        title="Modulo"
                        align={'center'}
                        width={20}
                        // render={(value) => String.fromCharCode(value + 65)}
                        render={(value) => <TagField color={'yellow'} value={String.fromCharCode(value + 65)} />}
                    />
                    <Table.Column
                        dataIndex="col1"
                        key="col1"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{record.desc_1 ? record.desc_1 : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '0')}>
                                <Button type="primary" style={{
                                    backgroundColor: value ? 'green' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>0</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col2"
                        key="col2"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{record.desc_2 ? record.desc_2  : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '1')}>
                                <Button type="primary" style={{
                                    backgroundColor: value ? 'green' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>1</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col3"
                        key="col3"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{record.desc_3 ? record.desc_3 : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '2')}>
                                <Button type="primary" style={{
                                    backgroundColor: value ? 'green' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>2</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col4"
                        key="col4"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{record.desc_4 ? record.desc_4  : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '3')}>
                                <Button type="primary" style={{
                                    backgroundColor: value ? 'green' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>3</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col5"
                        key="col5"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{record.desc_5 ? record.desc_5  : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '4')}>
                                <Button type="primary" style={{
                                    backgroundColor: value ? 'green' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>4</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col6"
                        key="col6"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{record.desc_6 ? record.desc_6  : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '5')}>
                                <Button type="primary" style={{
                                    backgroundColor: value ? 'green' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>5</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col7"
                        key="col7"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{record.desc_7 ? record.desc_7 : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '6')}>
                                <Button type="primary" style={{
                                    backgroundColor: value ? 'green' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>6</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col8"
                        key="col8"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{record.desc_8 ? record.desc_8 : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '7')}>
                                <Button type="primary" style={{
                                    backgroundColor: value ? 'green' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>7</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col9"
                        key="col9"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{record.desc_9 ? record.desc_9 : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '8')}>
                                <Button type="primary" style={{
                                    backgroundColor: value ? 'green' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>8</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col10"
                        key="col10"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{record.desc_10 ? record.desc_10 : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '9')}>
                                <Button type="primary" style={{
                                    backgroundColor: value ? 'green' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>9</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col11"
                        key="col11"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{record.desc_11 ? record.desc_11 : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '10')}>
                                <Button type="primary" style={{
                                    backgroundColor: value ? 'green' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>10</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col12"
                        key="col12"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{record.desc_12 ? record.desc_12 : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '11')}>
                                <Button type="primary" style={{
                                    backgroundColor: value ? 'green' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>11</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col13"
                        key="col13"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{record.desc_13 ? record.desc_13 : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '12')}>
                                <Button type="primary" style={{
                                    backgroundColor: value ? 'green' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>12</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col14"
                        key="col14"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{record.desc_14 ? record.desc_14 : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '13')}>
                                <Button type="primary" style={{
                                    backgroundColor: value ? 'green' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>13</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col15"
                        key="col15"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{record.desc_15 ? record.desc_15 : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '14')}>
                                <Button type="primary" style={{
                                    backgroundColor: value ? 'green' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>14</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col16"
                        key="col16"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{record.desc_16 ? record.desc_16 : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '15')}>
                                <Button type="primary" style={{
                                    backgroundColor: value ? 'green' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>15</Button>
                            </Popover>
                        </>}
                    />
                </Table>
            </div>
            <div hidden={!showUpateLog} style={{
                overflow: 'auto',
                maxHeight: window.innerHeight - 350
            }}>
                <div className="example" hidden={!loadLog}>
                    <Spin />
                </div>
                { [...updateLog].reverse().map( (r: any) => (
                    <Title level={5}>{r?.description} - IP: {r?.ip} - MODULI: {r?.deviceCount} - SERIALE: {r?.serial} - DATA: {dayjs(r?.data).format('DD-MM-YYYY HH:mm:ss')}</Title>
                ))}
            </div>
            <div hidden={!showUpateEsp} style={{
                overflow: 'auto',
                maxHeight: window.innerHeight - 350
            }}>
                <div className="example" hidden={!loadLogEsp}>
                    <Spin />
                </div>
                { [...updateEsp].reverse().map( (r: any) => {
                    return (
                        <>
                            <Title level={5}>{r?.device.description} - IP: {r?.device.ip} - MODULI: {r?.device.deviceCount} - SERIALE: {r?.device.serial} - DATA: {dayjs(new Date()).format('DD-MM-YYYY HH:mm:ss')}</Title>
                            { r.data.devices.map((d: any) => {
                                return (<Title level={5}>ID: {d?.id} - VALUE: {d?.values}</Title>)
                            })}
                        </>
                )})}
            </div>
        </List>
        );
};
