import React, {useState} from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Form, Input, Select, Row, Col, Switch } from "antd";

import {ICategory} from 'interfaces';
export const TurnEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps, queryResult} = useForm<ICategory>({
        meta: {
            populate: "*"
        }
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
