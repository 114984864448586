import {IResourceComponentsProps, useTranslate} from '@refinedev/core';

import {
    DateField,
    List, useSelect,
    useTable,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Button, Col, DatePicker, Form, Input, Row, Select, Space, Table} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import {ICategory, ICausal, ISeller, ITurn} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import dayjs from 'dayjs';
import {CSVLink} from 'react-csv';

export const ReportList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const [dataIn, setDataIn] = useState<any>();
    const [dataOut, setDataOut] = useState<any>();
    const [utenti, setUtenti] = useState<any>([]);
    const [valoriEntrata, setValoriEntrata] = useState<any>('');
    const [valoriUscita, setValoriUscita] = useState<any>('');
    const [valoriDiversi, setValoriDiversi] = useState<any>('');

    const categoryOptions = [
        {label: 'Tabacchi', value: 'tabacchi'},
        {label: 'Gratta e Vinci', value: 'gev'},
        {label: 'Scontrinato', value: 'scontrinato'},
        {label: 'Scontrinato 22%', value: 'scontrinato22'},
    ]

    const categoryUsciteOptions: any = []

    const variousOptions = [
        {label: 'Riassortimento', value: 'isRestock'},
        {label: 'Alert Cancellati', value: 'isDeleted'},
        {label: 'Differenza di Cassa', value: 'differenza'},
    ]

    const { selectProps: sellerSelectProps, defaultValueQueryResult, queryResult } = useSelect<ISeller>({
        resource: "sellers",
        meta: {
            populate: 'users_permissions_users, device'
        },
        filters: [
            {
                field: 'id',
                operator: 'eq',
                value: store.id
            }
        ],
        pagination: {
            mode: "client"
        }
    });

    const { selectProps: causaliSelectProps, queryResult: causaliResult } = useSelect<ICausal>({
        resource: "causals",
        meta: {
            populate: 'seller'
        },
        optionValue: 'acronimo',
        optionLabel: 'description',
        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            },
            {
                field: 'isTurn',
                operator: 'eq',
                value: true
            }
        ],
        pagination: {
            mode: "server",
            pageSize: 99999999
        }
    });

    if (causaliResult && causaliResult.data && causaliResult.data?.data.length > 0) {
        causaliResult.data.data.map( (x: any) => {
            // console.log(x);
            if (x.isLoad) {
                categoryOptions.push({label: x.description, value: x.acronimo})
            }
            if (x.isUnLoad) {
                categoryUsciteOptions.push({label: x.description, value: x.acronimo})
            }
        })
    }

    const { selectProps: categorySelectProps, queryResult: categoryResult, defaultValueQueryResult: defaultCategoryQueryResult } = useSelect<ICategory>({
        resource: "categories",
        optionValue: 'id',
        optionLabel: 'description',
        filters: [
            {
                operator: 'or',
                value: [
                    {
                        field: 'isTabacco',
                        operator: 'eq',
                        value: true
                    },
                    {
                        field: 'isGeneric',
                        operator: 'eq',
                        value: true
                    }
                ],
            },
        ],
        pagination: {
            mode: "client"
        }
    });

    const allOptions = [
        ...(queryResult.data?.data || []),
        ...(defaultValueQueryResult.data?.data || [])
    ];

    const { tableProps, tableQueryResult, searchFormProps} = useTable<ITurn>({
        resource: "turns",
        meta: {
            populate: 'user, seller'
        },
        queryOptions: {
            enabled: true
        },
        onSearch: (values: any) => {
            const dIn = new Date(values.dataIn);
            const dOut = new Date(values.dataOut);
            return [
                        {
                            field: 'user][id]',
                            operator: 'in',
                            value: values.user
                        },
                        {
                            field: 'dataIn',
                            operator: 'gte',
                            value: values?.dataIn ? new Date(dIn.getFullYear(), dIn.getMonth(), dIn.getDate(), 0,0,0).toISOString() : new Date('1970-01-01').toISOString()
                        },
                        {
                            field: 'dataIn',
                            operator: 'lte',
                            value: values?.dataOut ? new Date(dOut.getFullYear(), dOut.getMonth(), dOut.getDate(), 23,59,59).toISOString() : new Date('1970-01-01').toISOString()
                        }
            ];
        },
        filters: {
            permanent: [
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id
                }
            ]
        },
        pagination: {
            mode: "server",
            pageSize: 99999999
        }
    });

    const [showTable, setShowTable] = useState(false);

    const confirm = () => {
        setShowTable(true);
        searchFormProps.form?.submit();
    }

    useEffect(() => {

        if (tableQueryResult.isSuccess && tableQueryResult.isFetched && tableQueryResult.data.data.length > 0) {
            const tot0 = tableQueryResult.data?.data?.map((item: any) => Number(item?.dati?.exit[valoriUscita] ? item?.dati?.exit[valoriUscita] : 0)).reduce((prev, next) => prev + next);
            const tot1 = tableQueryResult.data?.data?.map((item: any) => valoriDiversi === 'differenza' ? Number(item?.dati?.differenza ? item?.dati?.differenza : 0) : 0).reduce((prev, next) => prev + next);
            const tot2 = tableQueryResult.data?.data?.map((item: any) => Number((item?.dati?.in?.[valoriEntrata] ? (item?.dati?.in?.[valoriEntrata] ? item?.dati?.in?.[valoriEntrata] : 0) : (item?.dati?.entrate?.[valoriEntrata] ? item?.dati?.entrate?.[valoriEntrata] : 0)))).reduce((prev, next) => prev + next);
            setDataFooter({tot0: Number(tot0).toFixed(2), tot1: Number(tot1).toFixed(2), tot2: Number(tot2).toFixed(2)});;
        }
    });


    const [dataExport, setDataExport] = useState<any>([]);
    const [dataFooter, setDataFooter] = useState<any>([]);
    const exportDati = () => {
        let dataArray: any = [];
        tableQueryResult.data?.data.map((res: any) => {
            // console.log(res);
            dataArray.push({
                data: res['dataIn'],
                utente: res['user']['name'],
                [valoriUscita]: parseFloat(res['dati']['exit'][valoriUscita]).toLocaleString('de', {
                    minimumFractionDigits: 2
                }),
                [valoriEntrata]: parseFloat(res['dati']['in'][valoriEntrata]).toLocaleString('de', {
                    minimumFractionDigits: 2
                }),
                [valoriDiversi]: valoriDiversi === 'differenza' ? parseFloat(res['dati'][valoriDiversi]).toLocaleString('de', {
                    minimumFractionDigits: 2
                }) : res['dati'][valoriDiversi]
            })
        })
        // console.log(dataArray);
        setDataExport(dataArray);
    }

    // console.log(categoryOptions);
    return (
            <List>
                <Form {...searchFormProps} layout="horizontal">
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item name={'dataIn'}>
                            <DatePicker
                                style={{width: '100%'}}
                                format={'DD-MM-YYYY'}
                                placeholder={'Dal...'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Select
                            style={{
                                width: '100%'
                            }}

                            placeholder={'Valori Entrate...'}
                            {...categorySelectProps}
                            options={categoryOptions.map( (d: any) => ({
                                label: d.label,
                                value: d.value
                            }))
                            }
                            allowClear={true}
                            onSelect={(e: any) => setValoriEntrata(e)}
                        />
                    </Col>
                    <Col span={6}>
                        <Form.Item name={'user'}>
                        <Select
                            style={{
                                width: '100%'
                            }}
                            placeholder={'Utenti...'}
                            {...sellerSelectProps}
                            mode={'multiple'}
                            options={allOptions[0]?.users_permissions_users?.map((p) => ({
                                label: p.name + ' ' + p.surname,
                                value: p.id
                            }))}
                            allowClear={true}
                        />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <CSVLink
                            separator={';'}
                            filename={'Report_' + dayjs().format('DD_MM_YYYY') + '.csv'}
                            data={dataExport}
                            asyncOnClick={true}
                            onClick={exportDati}
                        >
                            <Button type={'default'} style={{width: '100%'}} icon={<Icons.ExportOutlined />} >Export</Button>
                        </CSVLink>
                    </Col>
                </Row>
                <Row gutter={24} style={{marginTop: 10}}>
                    <Col span={8}>
                        <Form.Item name={'dataOut'}>
                            <DatePicker
                                style={{width: '100%'}}
                                format={'DD-MM-YYYY'}
                                placeholder={'Dal...'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Select
                            style={{
                                width: '100%'
                            }}
                            placeholder={'Valori Uscite...'}
                            {...causaliSelectProps}
                            options={categoryUsciteOptions.map( (d: any) => ({
                                label: d.label,
                                value: d.value
                            }))
                            }
                            allowClear={true}
                            onSelect={(e: any) => setValoriUscita(e)}
                        />
                    </Col>
                    <Col span={6}>
                        <Select
                            style={{
                                width: '100%'
                            }}

                            placeholder={'Valori Diversi...'}
                            options={variousOptions.map( (d: any) => ({
                                label: d.label,
                                value: d.value
                            }))
                            }
                            allowClear={true}
                            onSelect={(e: any) => setValoriDiversi(e)}
                        />
                    </Col>
                    <Col span={4}>
                        <Button type={'primary'} style={{width: '100%'}} onClick={confirm}>Conferma</Button>
                    </Col>
                </Row>
                </Form>
                <hr />
                <div hidden={!showTable}>
                    <Table size="small"  rowKey="id" {...tableProps}
                           sticky={true}
                           pagination={false}
                           scroll={{y: window.innerHeight - 350 + 'px'}}
                           footer={data => {
                               return (<><div style={{ textAlign: 'right'}}>{valoriUscita} : € {dataFooter.tot0} - {valoriEntrata} : € {dataFooter.tot2} - {valoriDiversi} : € {dataFooter.tot1}</div></>)
                           }
                               }
                          >
                        <Table.Column
                            dataIndex="dataIn"
                            key="dataIn"
                            title={'Data'}
                            render={(value) => (
                                <DateField value={value} format={'DD-MM-YYYY HH:mm'} />
                            )}
                        />
                        <Table.Column
                            dataIndex="user"
                            key="user"
                            title={'Utente'}
                            render={(value) => (
                                value?.name + ' ' + value?.surname
                            )}
                        />
                        <Table.Column
                            dataIndex="dati"
                            key={valoriUscita}
                            title={valoriUscita}
                            render={(value) => (
                                parseFloat(value?.exit?.[valoriUscita] ? value?.exit?.[valoriUscita] : 0).toLocaleString('de', {
                                    minimumFractionDigits: 2
                                }) + ' €'
                            )}
                        />
                        <Table.Column
                            dataIndex="dati"
                            key={valoriEntrata}
                            title={valoriEntrata}
                            render={(value) => (
                                parseFloat((value?.in?.[valoriEntrata] ? (value?.in?.[valoriEntrata] ? value?.in?.[valoriEntrata] : 0) : (value?.entrate?.[valoriEntrata] ? value?.entrate?.[valoriEntrata] : 0))).toLocaleString('de', {
                                    minimumFractionDigits: 2
                                }) + ' €'
                            )}
                        />
                        <Table.Column
                            dataIndex="dati"
                            key={valoriDiversi}
                            title={valoriDiversi}
                            render={(value, record) => {
                             return (
                                 valoriDiversi === 'differenza' ?
                                 parseFloat(value?.[valoriDiversi]).toLocaleString('de', {
                                     minimumFractionDigits: 2
                                 }) + ' €' : value?.[valoriDiversi]
                             )
                            }}
                        />

                    </Table>
                </div>
            </List>
    );
};
