import {
    CrudFilters,
    IResourceComponentsProps, useList,
    useTranslate,
} from '@refinedev/core';
import {
    List,
    TagField,
    useTable
} from '@refinedev/antd';
import {Button, Col, Dropdown, Form, Input, Modal, Popover, Row, Select, SelectProps, Space, Switch, Table} from 'antd';
import {IStock, IWareHousePosition} from 'interfaces';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import {useLocation} from 'react-router-dom';
import * as Icons from '@ant-design/icons';


export const MagazzinoStock: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const state: any = useLocation();
    const {value} = useContext(StoreContext);
    const [store, setStore] = value;

    const {tableProps, searchFormProps, setFilters, filters, tableQueryResult, setSorters} = useTable<IStock>({
        resource: "stocks",
        meta: {populate: 'seller, product, product.category'},
        pagination: {
            pageSize: 999,
            mode: "server"
        },
        onSearch: (params: any) => {
            const filters: CrudFilters = [];

            filters.push(
                {
                    operator: "or",
                    value: [
                        {
                            field: "product][barcodes][code]",
                            operator: "eq",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][barcode_packages][code]",
                            operator: "eq",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][description]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][codice]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][category][description]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                    ]
                },
                {
                    field: "product][category][id]",
                    operator: "contains",
                    value: (params?.category) ? params?.category : ''
                }
            );

            return filters;
        },
        filters: {
            permanent: [
                {
                    field: "seller.id",
                    operator: "eq",
                    value: store.id,
                },
                {
                    field: "giacenza",
                    operator: "gt",
                    value: 0
                }
            ]
        },
        sorters: {
            permanent: [
                {
                    field: 'product.description',
                    order: 'asc'
                }
            ]
        }
    });

    const barcode = useRef('');

    const wareHousePosition = useList<IWareHousePosition>({
        resource: "ware-house-positions",
        meta: {populate: 'seller, stock, stock.product, stock.product.category'},

        pagination: {
            pageSize: 9999,
            mode: "server"
        },

        filters: [
            {
                field: "seller.id",
                operator: "eq",
                value: store.id,
            },
            {
                field: "stock][giacenza]",
                operator: 'gt',
                value: 0
            },
            {
                field: "dataOut",
                operator: 'null',
                value: true
            },
            {
              operator: 'or',
              value: [
                  {
                      field: "stock][product][barcodes][code]",
                      operator: "contains",
                      value: barcode.current
                  },
                  {
                      field: "stock][product][barcode_packages][code]",
                      operator: "contains",
                      value: barcode.current
                  },
              ]
            }
        ],

        sorters: [
            {
                field: 'stock.description',
                order: 'asc'
            }
        ]
    });

    const [detailCategoryMagazzino, setDetailCategoryEspositoreMagazzino] = useState<any>([]);
    const [totMagazzino, setTotMagazzino] = useState<number>(0);
    const updateTot = () => {
        // GROUP BY CATEGORY
        const tempGroup: any = tableQueryResult.data?.data.reduce((r: any, a: any) => {
            // console.log("a", a);
            // console.log("r", r);
            r[a.product.category.description] = [...(r[a.product.category.description] || []), a];
            return r;
        }, {})

        // console.log(tempGroup)
        let priceCategoryEspositore = [];
        let priceCategoryMagazzino = [];

        for (let g in tempGroup) {
            priceCategoryEspositore.push({
                qta: Number(tempGroup[g].map((item: any) => item.scansiaPz).reduce((prev: number, next: number) => prev + next)).toFixed(0),
                category: g,
                tot: parseFloat(Number(tempGroup[g].map((item: any) => item.product.price * item.scansiaPz).reduce((prev: number, next: number) => prev + next)).toFixed(2))
            })
            priceCategoryMagazzino.push({
                qta: Number(tempGroup[g].map((item: any) => item.giacenza).reduce((prev: number, next: number) => prev + next)).toFixed(0),
                category: g,
                tot: parseFloat(Number(tempGroup[g].map((item: any) => (item.product.price * item.nrPezziStecca) * item.giacenza).reduce((prev: number, next: number) => prev + next)).toFixed(2))
            })
        }
        setDetailCategoryEspositoreMagazzino(priceCategoryMagazzino);
    }

    useEffect(() => {
        if (tableQueryResult && tableQueryResult.data && tableQueryResult.data.data.length > 0) {
            if (wareHousePosition && wareHousePosition.data && wareHousePosition.data?.data?.length > 0) {
                setTotMagazzino(parseFloat(Number(wareHousePosition.data?.data.map(item => item?.stock?.product?.price * item?.stock?.nrPezziStecca).reduce((prev, next) => prev + next)).toFixed(2)));
            }
        } else {
            setTotMagazzino(0);
        }
        if (state && state.state && state.state.type === 3) {
            // handleStock();
        }
    }, [tableQueryResult.data?.data?.length]);

    useEffect(() => {
        updateTot();
    }, [tableQueryResult.isFetching])

    const [form] = Form.useForm();
    const handleSearch = () => {
        console.log(form.getFieldValue('description'));
        barcode.current = form.getFieldValue('description');
        wareHousePosition.refetch()
    };

    const resetFilter = () => {
        searchFormProps.form?.resetFields()
        // console.log('Reset:', )
    };

    return (
        <List
            title={'Magazzino - Importo Totale:  € ' +
                Number(parseFloat(totMagazzino.toString()).toFixed(2)).toLocaleString('de', {
                    minimumFractionDigits: 2
                })}
            headerProps={{
                extra: [
                    <>
                        <Row>
                            <Col style={{ marginRight: 10}} >
                                <Form form={form} layout="horizontal" labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}>
                                    <Form.Item label="Barcode" name="description">
                                        <Input
                                            onPressEnter={handleSearch}
                                            allowClear={true}
                                            placeholder="Cerca"
                                            prefix={<Icons.SearchOutlined />}
                                        />
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </>
                ],
            }}
        >
            <Table size="small" dataSource={wareHousePosition.data?.data}
                   key="id"
                   rowKey="id"
                   sticky={false}
                   scroll={{y: window.innerHeight - 293 + 'px'}}
                   pagination={false}
                   footer={data =>
                       detailCategoryMagazzino.map((x: any) => {
                           return (<>
                               <TagField value={x?.category + ': ' + x.qta} color={'gold'}/>
                               <span style={{
                                   marginRight: 15,
                                   color: 'coral'
                               }}>{Number(parseFloat(x?.tot).toFixed(2)).toLocaleString('de', {
                                   minimumFractionDigits: 2
                               })} €</span>
                           </>)
                       })
                   }
            >
                <Table.Column
                    dataIndex="stock"
                    key="stock.product.codice"
                    title="Codice"
                    render={(value) => {
                        return value?.product?.codice}}
                />
                <Table.Column
                    dataIndex="stock"
                    key="stock.product.description"
                    title={translate('pages.stock.product', 'Descrizione')}
                    render={(value) => value?.product?.description}
                    filters={[
                        {
                            text: 'Tabacchi',
                            value: 'isTabacco',
                        },
                        {
                            text: 'Gratta e Vinci',
                            value: 'isGeneric',
                        }
                    ]}
                    onFilter={(value: any, record: any) => {
                        return record?.stock?.product?.category[value];
                    }}
                />
                <Table.Column
                    dataIndex="position"
                    key="position"
                    title={translate('pages.stock.position', 'Posizione')}
                    render={(value, record: any) => {
                        return value
                    }
                    }
                />
                <Table.Column
                    dataIndex="stock"
                    key="totale"
                    title={translate('pages.stock.totale', 'Totale')}
                    render={(value, record: any) => {
                        const totalPrice = value?.product?.price * value?.nrPezziStecca;
                        return Number(parseFloat(totalPrice.toString()).toFixed(2)).toLocaleString('de', {
                            minimumFractionDigits: 2
                        }) + " €"
                    }
                    }
                />
            </Table>
        </List>

    );
};
