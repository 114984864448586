import { IResourceComponentsProps, useExport, useTranslate } from "@refinedev/core";

import {
    BooleanField,
    CreateButton, DateField,
    DeleteButton,
    EditButton,
    ExportButton,
    List,
    TagField,
    useTable,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Space, Table } from "antd";
import React, {useContext} from 'react';
import {ICausal, IPatent, IWhareHouseMovement} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import {PatentCreate} from './create';

export const PatentList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const {tableProps} = useTable<IPatent>({
        resource: "patents",
        meta: {populate: '*'},

        filters: {
            permanent: [
                {
                    field: "seller][id]",
                    operator: 'eq',
                    value: store.id
                }
            ]
        }
    });

    const {triggerExport, isLoading: exportLoading} = useExport<IPatent>();

    return (
            <List
                title={translate('pages.patent.title', 'Patentini')}
                headerProps={{
                    extra: [
                        <CreateButton/>,
                        <ExportButton
                            onClick={triggerExport}
                            loading={exportLoading}
                        />
                    ],
                }}
            >
                <Table size="small" {...tableProps} rowKey="id"
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   // console.log("patent", record);
                               },
                           };
                       }}
                       sticky={true}

                       pagination={{
                           ...tableProps.pagination,
                           showSizeChanger: true,
                       }}>
                    {/*<Table.Column dataIndex="id" key="id" title="ID"/>*/}
                    <Table.Column
                        dataIndex="company"
                        key="company"
                        title={translate('pages.patent.company', 'Ragione Sociale')}
                    />
                    <Table.Column
                        dataIndex="license"
                        key="license"
                        title={translate('pages.patent.license', 'Licenza')}
                    />
                    <Table.Column
                        dataIndex="address"
                        key="address"
                        title={translate('pages.patent.address', 'Indirizzo')}
                    />
                    <Table.Column
                        dataIndex="city"
                        key="city"
                        title={translate('pages.patent.city', 'Città')}
                    />
                    <Table.Column
                        dataIndex="zip"
                        key="zip"
                        title={translate('pages.patent.zip', 'Cap')}
                    />
                    <Table.Column
                        dataIndex="expireDate"
                        key="expireDate"
                        title={translate('pages.patent.expireDate', 'Data Scadenza')}
                        render={(value, record: IPatent, index) => {
                            return (
                                <DateField value={record.expireDate} format={'DD/MM/YYYY'}></DateField>
                            )
                        }}
                    />
                    <Table.Column<{ id: string }>
                        title={translate('pages.patent.action', 'Azioni')}
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                                <DeleteButton
                                    mutationMode={'undoable'}
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Space>
                        )}
                    />

                </Table>
            </List>
    );
};
