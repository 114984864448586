import React, {useContext, useEffect, useRef, useState} from 'react';

import {
    IResourceComponentsProps,
    useCreate,
    useGetIdentity,
    useList,
    useNotification,
    useUpdate,
} from "@refinedev/core";

import { Edit, useForm, DeleteButton, useSelect, useTable } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
    Form,
    Input,
    Select,
    Row,
    Col,
    DatePicker,
    Divider,
    Table,
    Space,
    Typography,
    Button,
    InputNumber,
} from "antd";

import {
    ICausal,
    IDocType,
    IStock,
    IWareHousePosition,
    IWhareHouseMovement,
    IWhareHouseMovementRow
} from 'interfaces';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import {StoreContext} from '../../contexts/StoreContext';
import {SocketContext} from '../../contexts/socket';
import {CSVLink} from 'react-csv';

export const WhareHouseMovementEdit: React.FC<IResourceComponentsProps> = () => {
    const inputElement: any = useRef(null) || '';
    const {formProps, saveButtonProps, queryResult, id} = useForm<IWhareHouseMovement>({
        resource: 'whare-house-movements',
        meta: {
            populate: 'seller, user, doc_type, causal'
        },
    });
    const navigate = useNavigate();
    const socket = useContext(SocketContext);
    const {open, close} = useNotification();
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const {data: userIdentity} = useGetIdentity<any>();
    const [textSearch, setTextSearch] = useState<any>();
    const [openRow, setOpenRow] = useState<boolean>(true);
    const [totale, setTotale] = useState(0);
    const [tempSearch, setTempSearch] = useState<any>();
    const updateWhareHouseMovement = useUpdate<IWhareHouseMovement>();
    const [showSave, setShowSave] = useState<boolean>(false);

    const {selectProps: docTypeSelectProps} = useSelect<IDocType>({
        resource: 'doc-types',
        optionValue: 'id',
        optionLabel: 'description',
        defaultValue: queryResult?.data?.data?.doc_type?.id,
    });

    const {selectProps: causalSelectProps} = useSelect<ICausal>({
        resource: 'causals',
        optionValue: 'id',
        optionLabel: 'description',
        defaultValue: queryResult?.data?.data?.causal?.id,
    });

    const {selectProps, queryResult: queryResultProduct} = useSelect<IStock>({
        resource: 'stocks',
        optionLabel: 'description',
        optionValue: 'id',

        queryOptions: {
            enabled: true,
        },

        meta: {
            populate: 'product'
        },

        filters: [
            {
                operator: 'or',
                value: [
                    {
                        field: 'product][barcodes][code]',
                        operator: 'eq',
                        value: textSearch
                    },
                    {
                        field: 'product][barcode_packages][code]',
                        operator: 'eq',
                        value: textSearch
                    },
                    {
                        field: 'description',
                        operator: 'contains',
                        value: textSearch
                    }
                ]
            },
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ],

        pagination: {
            mode: "client"
        }
    });

    const barcodeSearchData = useList<IStock>({
        resource: 'stocks',

        queryOptions: {
            enabled: false,
        },

        meta: {
            populate: 'product'
        },

        filters: [
            {
                operator: 'or',
                value: [
                    {
                        field: 'product][barcodes][code]',
                        operator: 'eq',
                        value: textSearch
                    },
                    {
                        field: 'product][barcode_packages][code]',
                        operator: 'eq',
                        value: textSearch
                    }
                ]
            },
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ]
    });

    const handleSearch = (value: string) => {
        setTextSearch(value);
    };

    const handleSearchBarcode = (e: any) => {
        // console.log(e.key);
        if (e.keyCode === 13) {
            // console.log(tempSearch);
            setTextSearch(tempSearch);
            setTimeout(() => {
                barcodeSearchData.refetch().then(res => {
                    // console.log(res);
                    if (res && res.data && res.data.data.length > 0) {
                        addWhareHouseMovementRow.mutateAsync({
                            resource: 'whare-house-movement-rows',
                            successNotification: false,
                            values: {
                                whare_house_movement: id,
                                product: res.data?.data[0].product?.id,
                                stock: res.data?.data[0]?.id,
                                seller: store.id,
                                users: userIdentity.id,
                                description: res.data?.data[0].product?.description,
                                price: res.data?.data[0]?.product?.price,
                                aggio: res.data?.data[0]?.aggio,
                                nrPezziStecca: res.data?.data[0]?.nrPezziStecca,
                                qta: 1,
                                isPresent: true
                            }
                        }).then( tot => {
                            setTempSearch('')
                            tableQueryResult.refetch();
                        })
                    } else {
                        setTempSearch('');
                        open?.({
                            type: 'error',
                            message: 'Articolo non presente in Stock...',
                            description: 'Attenzione!',
                        });
                    }
                })
            }, 600)
        }
    };

    const addWhareHouseMovementRow = useCreate<IWhareHouseMovementRow>();
    const handleSelect = (value: any) => {
        // console.log(value);
        // console.log(queryResultProduct);
        const c: any = queryResultProduct.data?.data.findIndex(x => x.id === value);
        // console.log(c);
        // console.log(queryResultProduct.data?.data[c]);
        addWhareHouseMovementRow.mutateAsync({
            successNotification: false,
            resource: 'whare-house-movement-rows',
            values: {
                whare_house_movement: id,
                product: queryResultProduct.data?.data[c]?.product?.id,
                stock: queryResultProduct.data?.data[c]?.id,
                seller: store.id,
                users: userIdentity.id,
                description: queryResultProduct.data?.data[c]?.product?.description,
                price: queryResultProduct.data?.data[c]?.product?.price,
                aggio: queryResultProduct.data?.data[c]?.aggio,
                nrPezziStecca: queryResultProduct.data?.data[c]?.nrPezziStecca,
                qta: 1,
                isPresent: true
            }
        }).then( tot => {
            setTempSearch('')
            tableQueryResult.refetch();
        })
    };

    const {tableProps, tableQueryResult} = useTable<IWhareHouseMovementRow>({
        resource: 'whare-house-movement-rows',

        queryOptions: {
            enabled: true
        },

        meta: {
            populate: 'seller, product, stock, whare-house-movement'
        },

        pagination: {
            pageSize: 1000,
            mode: 'server'
        },

        filters: {
            permanent: [
                {
                    field: 'whare_house_movement][id]',
                    operator: 'eq',
                    value: id
                }
            ]
        },

        sorters: {
            initial: [
                {
                    field: 'createdAt',
                    order: 'desc'
                }
            ]
        }
    });

    const {Title} = Typography;

    const updateTot = () => {
        if (tableQueryResult.isSuccess && tableQueryResult.data.data.length > 0) {
            // setTotale(parseFloat(Number(tableQueryResult.data?.data.map(item => item?.product?.price * (Math.round(1000 / (item?.product?.priceKg / item?.product?.price)) / 2)).reduce((prev, next) => prev + next)).toFixed(2)));
            setTotale(parseFloat(Number(tableQueryResult.data?.data.map(item => ((item?.price * item?.nrPezziStecca) - ((item?.price * item?.nrPezziStecca) / 100) * item?.aggio)).reduce((prev, next) => prev + next)).toFixed(2)));
        } else {
            setTotale(0)
        }
        if (formProps.form?.getFieldValue('totalExt') === totale) {
            setShowSave(true);
        } else {
            setShowSave(false);
        }
    }

    useEffect(() => {
        updateTot();
    })

    const enableLoad = () => {
        updateWhareHouseMovement.mutateAsync({
            resource: 'whare-house-movements',
            successNotification: false,
            id: Number(id),
            values: {
                isLoad: true
            }
        }).then((r: any) => {
            console.log('Successfully Setting isLoad -> true: ', id);
        })
    }
    useEffect(() => {
        socket.on('sendWhareHouse', async (data: any, error: any) => {
            // console.log(data);
            tableQueryResult.refetch();
        });
        socket.on('alertArticoloNonPresente', async (data: any, error: any) => {
            // console.log(data);
            open?.({
                type: 'error',
                message: 'Si è verificato un errore nel Carico...Articolo non presente in Anagrafica Articoli',
                description: 'Attenzione',
            });
        });
        return () => {
            socket.off('sendWhareHouse');
            socket.off('alertArticoloNonPresente');
        };
    }, [])

    const onConfirm = (data: any) => {
        // console.log(data, totale);
        // if (data.totalExt === totale) {
            updateWhareHouseMovement.mutateAsync({
                resource: 'whare-house-movements',
                successNotification: false,
                id: Number(id),
                values: {
                    isLoad: false,
                    isTemporary: false,
                    total: totale,
                    totalExt: data.totalExt,
                    docNumber: data.docNumber,
                    docNumberExt: data.docNumberExt,
                }
            }).then((r: any) => {
                if (tableQueryResult && tableQueryResult.data && tableQueryResult.data.data) {
                    wareHousePosition.refetch().then(pos => {
                        if (pos && pos.data && pos.data.data.length > 0) {
                            for (let x of tableQueryResult.data.data) {
                                // console.log(x);
                                // console.log(pos);
                                // Imposto WareHousePosition to isTemp = true
                                const indexHousePosition = pos.data.data.findIndex(wp => wp.position === x.position);
                                // console.log(indexHousePosition);
                                if (indexHousePosition !== -1) {
                                    updateWareHousePosition.mutate({
                                        resource: 'ware-house-positions',
                                        successNotification: false,
                                        id: Number(pos.data?.data[indexHousePosition].id),
                                        values: {
                                            isTemp: false
                                        }
                                    })
                                }
                                // console.log(tableQueryResult);

                            }
                            navigate('/whare-house-movements');
                        }
                    })
                }
                console.log('Successfully Edit: ', id);
            })
        // } else {
        //     open?.({
        //         type: 'error',
        //         message: 'Il Totale Documento non corrisponde con il Totale Caricato...',
        //         description: 'Attenzione!',
        //     });
        // }

    }

    const onSaveTemp = () => {
        // console.log('Salvo: ', formProps.form?.getFieldValue('totalExt'), totale);
        updateWhareHouseMovement.mutateAsync({
            resource: 'whare-house-movements',
            successNotification: false,
            id: Number(id),
            values: {
                isLoad: true,
                isTemporary: true,
                totalExt: formProps.form?.getFieldValue('totalExt'),
                total: totale
            }
        }).then(rrr => {
            if (tableQueryResult && tableQueryResult.data && tableQueryResult.data.data) {
                wareHousePosition.refetch().then(pos => {
                    setTimeout(() => {
                        if (pos && pos.data && pos.data.data.length > 0) {
                            for (let x of tableQueryResult.data.data) {
                                // console.log(x.position);
                                // console.log(pos);

                                // Imposto WareHousePosition to isTemp = true
                                const indexHousePosition = pos.data.data.findIndex(wp => wp.position === x.position);
                                // console.log(indexHousePosition);
                                if (indexHousePosition !== -1) {
                                    updateWareHousePosition.mutate({
                                        resource: 'ware-house-positions',
                                        successNotification: false,
                                        id: Number(pos.data?.data[indexHousePosition].id),
                                        values: {
                                            isTemp: true
                                        }
                                    })
                                }
                                // console.log(tableQueryResult);

                                // console.log(wareHousePosition);
                            }
                            navigate('/whare-house-movements');
                        }
                    }, 600)
                })
            }
        });
    }

    const updateWareHousePosition = useUpdate<IWareHousePosition>();

    const wareHousePosition = useList<IWareHousePosition>({
        resource: 'ware-house-positions',
        meta: {populate: 'seller, stock, stock.product'},

        queryOptions: {
            enabled: true
        },

        pagination: {
            pageSize: 100000000,
            mode: 'server'
        },

        filters: [
            {
                field: 'seller.id',
                operator: 'eq',
                value: store.id,
            },
            {
                field: 'dataOut',
                operator: 'null',
                value: undefined
            }
        ]
    });

    const handleFocus = (event: any) => {
        event.target.select();
    };

    const [arrayExport, setArrayExport] = useState<any>([]);
    const exportDocumento = () => {
        // console.log(queryResult);
        // console.log(tableQueryResult);
        let dataExport = [];
        dataExport.push({
            tipo: queryResult?.data?.data.doc_type.description,
            causale: queryResult?.data?.data.causal.description,
            data: queryResult?.data?.data.loadDate,
            numeroDoc: queryResult?.data?.data.docNumber,
            numeroExt: queryResult?.data?.data.docNumberExt,
            totaleExt: queryResult?.data?.data.totalExt,
            totaleDoc: queryResult?.data?.data.total,
        });
        tableQueryResult.data?.data.map((res: IWhareHouseMovementRow) => {
            dataExport.push({
                ams: res.product.codice,
                descrizione: res.product.description,
                prezzo: res.price.toFixed(2),
                aggio: res.aggio,
                posizione: "\"" + res.position
            })
        });
        setArrayExport(dataExport);
    }

    return (
        <Edit
            headerButtons={
                <Space>
                    <CSVLink
                        separator={';'}
                        filename={'Movimento_' + dayjs().format('DD_MM_YYYY') + '.csv'}
                        data={arrayExport}
                        asyncOnClick={true}
                        onClick={exportDocumento}
                    >
                        <Button type={'default'} htmlType={'button'} icon={<Icons.ExportOutlined />}>Export</Button>
                    </CSVLink>
                </Space>
            }
            footerButtons={
            <Space>
                <Button type={'default'} htmlType={'button'} onClick={onSaveTemp} hidden={true}>Salva Temporaneo</Button>
                <Button type={'primary'} htmlType={'submit'} onClick={formProps.form?.submit} hidden={true}>Salva</Button>
            </Space>

        }>
            <Form
                {...formProps}
                layout="vertical"
                onFinish={onConfirm}
            >
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item
                            label="Tipo"
                            name={['doc_type', 'id']}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...docTypeSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Causale"
                            name={['causal', 'id']}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...causalSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Data"
                            name="loadDate"
                            getValueProps={(value) => ({
                                value: value ? dayjs(value) : '',
                            })}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker style={{width: '100%'}} format={'DD-MM-YYYY'}/>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="N. Doc"
                            name="docNumber"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="N. Ext"
                            name="docNumberExt"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Tot. Doc Ext"
                            name="totalExt"
                            // rules={[{
                            //     required: true,
                            //     message: "A value must be entered",
                            //     pattern: new RegExp(/\$\s?|(\.)|(,)^[0-9]+$/)
                            // }]}
                        >
                            <InputNumber
                                style={{width: '100%'}}
                                precision={2}
                                decimalSeparator={','}
                                onChange={updateTot}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Action"
                            name="buttonAction"
                        >
                            <Button style={{width: '100%'}} type={'primary'} htmlType={'button'}
                                    onClick={enableLoad}>Enable</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div hidden={!openRow}>
                <Divider/>
                <Row gutter={24}>
                    <Col span={12} style={{marginBottom: 20}}>
                        <Input style={{
                            width: '100%'
                        }}
                               name={'codice'}
                               placeholder={'Codice a barre...'}
                               ref={inputElement}
                               onFocus={(e) => handleFocus(e)}
                               onChange={event => setTempSearch(event.target.value)}
                               onKeyDown={(e) => handleSearchBarcode(e)}
                               allowClear={true}
                               value={tempSearch}
                        />
                    </Col>
                    <Col span={12} style={{marginBottom: 20}}>
                        <Select style={{
                            width: '100%'
                        }}
                                placeholder={'Ricerca Manuale...'}
                                {...selectProps}
                                onSearch={(e) => handleSearch(e)}
                                allowClear={true}
                                onSelect={(e) => handleSelect(e)}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Table {...tableProps}
                               pagination={false}
                               scroll={{y: 400}}
                        >
                            <Table.Column
                                dataIndex="product"
                                key="product_desc"
                                title="Descrizione"
                                render={(value) => value.description}
                            />
                            <Table.Column
                                dataIndex="price"
                                key="price"
                                title="Price"
                                render={(value) => (
                                    Number(parseFloat(value).toFixed(2)).toLocaleString('de', {
                                        minimumFractionDigits: 2
                                    }) + ' €'
                                )}
                            />
                            <Table.Column
                                dataIndex="aggio"
                                key="aggio"
                                title="Aggio %"
                            />
                            <Table.Column
                                dataIndex="stock"
                                key="stock"
                                title="Price Package"
                                render={(value, record: any) => {
                                    const pricePak = (record?.price * value?.nrPezziStecca) - (((record?.price * value?.nrPezziStecca) / 100) * value?.aggio);
                                    return Number(parseFloat(pricePak.toString()).toFixed(2)).toLocaleString('de', {
                                        minimumFractionDigits: 2
                                    }) + ' €'
                                }
                            }
                            />
                            <Table.Column
                                dataIndex="position"
                                key="position"
                                title="Position"
                            />
                            <Table.Column<{ id: string }>
                                title={'Actions'}
                                dataIndex="actions"
                                render={(_, record) => (
                                    <Space>
                                        <DeleteButton
                                            hidden={true}
                                            resource="whare-house-movement-rows"
                                            successNotification={false}
                                            hideText
                                            size="small"
                                            recordItemId={record.id}
                                        />
                                    </Space>
                                )}
                            />
                        </Table>
                    </Col>
                </Row>
            </div>
            <div hidden={!openRow}>
                <Divider/>
                <Title level={3} style={{textAlign: 'center'}}>Totale: {(
                    Number(parseFloat(totale.toString()).toFixed(2)).toLocaleString('de', {
                        minimumFractionDigits: 2
                    }) + ' €'
                )}</Title>
            </div>
        </Edit>
    );
};
