import { IResourceComponentsProps, useCreate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import {Col, DatePicker, Form, Input, InputNumber, Row, Select, Switch} from 'antd';
import {ICausal, IDocType, IPatent, ISeller} from 'interfaces';
import React, {useContext} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import { useNavigate } from "react-router-dom";
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';
import dayjs from 'dayjs';


export const PatentCreate: React.FC<IResourceComponentsProps> = () => {
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const navigate = useNavigate();
    const utilityHelper = UtilityHelper(API_URL + "/api");
    const {formProps, saveButtonProps} = useForm<IPatent>({
        resource: 'patents',
        warnWhenUnsavedChanges: false

    });

    const { selectProps: sellerSelectProps } = useSelect<ISeller>({
        resource: "sellers",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            mode: "server"
        }
    });

    const save = useCreate<IPatent>();

    const onFinish = (value: any) => {
        // console.log(value)
        utilityHelper.permission().then( r => {
            // console.log(r);
            if (r !== 'admin') {
                value.seller = store.id;
            }
            save.mutateAsync({
                resource: 'patents',
                values: value
            }).then( res => {
                navigate('/ptn/patents');
            })
        })

    }

    return (
            <Create saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={store.id ? 12 : 6}>
                            <Form.Item
                                label="Ragione Sociale"
                                name="company"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={6} hidden={store?.id}>
                            <Form.Item
                                label="Seller"
                                name="seller"
                                rules={[
                                    {
                                        required: !store?.id,
                                    },
                                ]}
                            >
                                <Select {...sellerSelectProps} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Licenza"
                                name="license"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Aggio %"
                                name="aggio"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item
                                    label="Indirizzo"
                                    name="address"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Città"
                                    name="city"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Cap"
                                name="zip"
                                rules={[
                                    {
                                        required: false,
                                        max: 5,
                                        len: 5
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Data Scadenza"
                                name="expireDate"
                                getValueProps={(value) => ({
                                    value: value ? dayjs(value) : '',
                                })}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <DatePicker style={{width: '100%'}} format={'DD-MM-YYYY'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Create>
    );
};
