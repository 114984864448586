import {
    CrudFilters,
    IResourceComponentsProps, useCreate, useList, useNotification,
    useTranslate,
} from '@refinedev/core';
import {
    DeleteButton,
    EditButton,
    List,
    ShowButton, useModal,
    useModalForm, useSelect,
    useTable
} from '@refinedev/antd';
import {
    Button,
    Col,
    Dropdown,
    Form,
    Input,
    InputRef,
    Modal,
    Popover,
    Row,
    Select,
    SelectProps,
    Space,
    Switch,
    Table
} from 'antd';
import {ICategory, IProduct, IRestock, IStock} from 'interfaces';
import React, {useContext, useRef, useState} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import {useLocation, useNavigate} from 'react-router-dom';
import * as Icons from '@ant-design/icons';


export const ReferenzeStock: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const state: any = useLocation();
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const {tableProps, searchFormProps, setFilters, filters, tableQueryResult, setSorters} = useTable<IStock>({
        resource: "stocks",
        meta: {populate: 'seller, product, product.category'},

        onSearch: (params: any) => {
            const filters: CrudFilters = [];

            filters.push(
                {
                    operator: "or",
                    value: [
                        {
                            field: "product][barcodes][code]",
                            operator: "eq",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][barcode_packages][code]",
                            operator: "eq",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][description]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][codice]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][category][description]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                    ]
                },
                {
                    field: "product][category][id]",
                    operator: "contains",
                    value: (params?.category) ? params?.category : ''
                }
            );

            return filters;
        },
        pagination: {
            pageSize: 999,
            mode: "server"
        },

        filters: {
            permanent: [
                {
                    field: "seller.id",
                    operator: "eq",
                    value: store.id,
                },
            ]
        },

        sorters: {
            permanent: [
                {
                    field: 'product.description',
                    order: 'asc'
                }
            ]
        }
    });

    const navigate = useNavigate();

    const handleSubmit = () => {
        close()
        navigate('/stk/referenze');
    }

    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
        close
    } = useModalForm<IStock>({
        action: "edit",
        resource: "stocks",
        redirect: false,
        onMutationSuccess: (() => { handleSubmit() }),
        meta: {populate: '*'},
        warnWhenUnsavedChanges: false,
    });

    const [form] = Form.useForm();

    const { selectProps: categorySelectProps } = useSelect<ICategory>({
        resource: "categories",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            pageSize: 999999,
            mode: "server"
        }
    });

    const [visible, setVisible] = useState(false);
    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };

    const resetFilter = () => {
        searchFormProps.form?.resetFields()
        // console.log('Reset:', )
    };

    const { show, close: closeModal, modalProps } = useModal();
    const createStock = useCreate<IStock>();
    const [tempArticolo, setTempArticolo] = useState<any>([]);

    const {data: restockList} = useList<IRestock>({
        resource: 'restocks',

        queryOptions: {
            enabled: false
        },
        pagination: {
            pageSize: 99999,
            mode: "server"
        },
        meta: {
            populate: 'product, seller, stock'
        },

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ]
    });

    const createRestock = useCreate<IRestock>();
    const saveAddArticolo = () => {
        tempArticolo.map((art: any) => {
            // console.log("CODICE:", art.codice)
            // console.log("ID: ", art.id)
            // console.log("TABLE: ", tableQueryResult)
            const idArticolo = tableQueryResult.data?.data.findIndex(x => x.product.id === art.id);
            // console.log('YYY: ', idArticolo);
            const idRestock = restockList?.data?.findIndex(x => x.product.id === art.id);
            // console.log('XXX: ', idRestock);
            if (idArticolo === -1) {
                createStock.mutateAsync({
                    resource: 'stocks',
                    successNotification: false,
                    values: {
                        description: art.description,
                        price: art.price,
                        nrPezziStecca: art.nrPezziStecca,
                        pesoFiscaleConf: art.pesoFiscaleConf,
                        product: art.id,
                        seller: store.id
                    }
                }).then( (res: any) => {
                    // console.log(res.data.data.id);
                    if (idRestock === -1) {
                        createRestock.mutateAsync({
                            resource: 'restocks',
                            successNotification: false,
                            values: {
                                description: art.description,
                                isRiassortito: false,
                                qtaRiassortimento: 0,
                                qtaRiassortita: 0,
                                product: art.id,
                                stock: res.data.data.id,
                                seller: store.id,
                            }
                        }).then( res => {
                            tableQueryResult.refetch()
                        })
                    }
                })
            }
        })
        setTempArticolo([]);
        closeModal();
    }

    const handleFocus = (event: any) => {
        event.target.select();
    };

    const [tempSearch, setTempSearch] = useState<any>();
    const [textSearch, setTextSearch] = useState<any>();

    const { selectProps, queryResult: productResult } = useSelect<IProduct>({
        resource: "products",
        optionLabel: "description",
        optionValue: "id",

        meta: {
            populate: "barcodes, barcode_packages"
        },

        queryOptions: {
            enabled: false
        },
        filters: [
            {
                operator: "or",
                value: [
                    {
                        field: "barcodes][code]",
                        operator: "eq",
                        value: textSearch
                    },
                    {
                        field: "barcode_packages][code]",
                        operator: "eq",
                        value: textSearch
                    },
                    {
                        field: "description]",
                        operator: "contains",
                        value: textSearch
                    }
                ]
            },
        ],

        pagination: {
            pageSize: 9999,
            mode: "server"
        },
    });

    const { open } = useNotification();
    const handleSearch = (e: any) => {
        setTextSearch(tempSearch);
        setTimeout(() => {
            productResult.refetch().then( (res: any) => {
                if (res && res.data && res.data.data.length === 1) {
                    // console.log(res.data.data);
                    const checkExists = tempArticolo.findIndex((x: any) => x.id === res.data?.data[0]?.id);
                    if (checkExists === -1) {
                        // console.log(checkExists);
                        // console.log(tempArticolo);
                        res.data.data[0].key = new Date().getTime();
                        //tempArticolo.sort((a: any, b: any) => Number(a.key) > Number(b.key));
                        setTempArticolo([...tempArticolo, res.data?.data[0]])
                    } else {
                        open?.({
                            type: "error",
                            message: "Articolo già presente nella lista",
                            description: "Attenzione!",
                        });
                    }

                }
            })
            setTempSearch('')
        }, 500)
    };

    const delTempArticolo = (record: any) => {
        // console.log(record, tempArticolo);
        const newIndex = tempArticolo.findIndex((i: any) => i.codice === record.codice);
        tempArticolo.splice(newIndex, 1);
        setTempArticolo([...tempArticolo]);
    }

    const [allineamento, setAllineamento] = useState(false);
    const { confirm } = Modal;
    const inputRef: any = useRef<InputRef>(null);
    const showConfirm = () => {
        confirm({
            title: 'Per effettuare la variazione è richiesta una password...',
            icon: <Icons.ExclamationCircleFilled />,
            content: <>
                <Input type={'password'} ref={inputRef} placeholder={'Password...'}></Input>
            </>,
            onOk() {
                if (inputRef.current.input.value === 'Espo2023') {
                    localStorage.setItem('pass', inputRef.current.input.value);
                    setAllineamento(true);
                };
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handleAllineamento = () => {
        if (localStorage.getItem('pass') === 'Espo2023') {
            setAllineamento(true);
        } else {
            showConfirm()
        }
    }

    return (
        <List
            title={'Referenze'}
            headerProps={{
                extra: [
                    <>
                        <Row>
                            <Col style={{ marginRight: 10}}>
                                <Button type={'primary'} style={{
                                    backgroundColor: 'green'
                                }} onClick={show}>Nuovo Articolo</Button>
                            </Col>
                            <Col style={{ marginRight: 10}}>
                                <Button type={'primary'} style={{
                                    backgroundColor: 'blue'
                                }} onClick={handleAllineamento}>Variazione</Button>
                            </Col>
                            <Col style={{ marginRight: 10}} >
                                <Popover
                                    placement="leftTop"
                                    style={{
                                        float: "right"
                                    }}
                                    content={
                                        <Form form={form} layout="horizontal" {...searchFormProps} labelCol={{ span: 8 }}
                                              wrapperCol={{ span: 16 }}>
                                            <Form.Item label="Cerca" name="description">
                                                <Input
                                                    allowClear={true}
                                                    placeholder="Cerca"
                                                    prefix={<Icons.SearchOutlined />}
                                                />
                                            </Form.Item>
                                            <Form.Item label="Categoria" name="category">
                                                <Select
                                                    {...categorySelectProps}
                                                    allowClear={true}
                                                    placeholder="Categoria"

                                                />
                                            </Form.Item>
                                            <Form.Item>
                                                <Space>
                                                    <Button htmlType="submit" type="primary" onClick={() => handleVisibleChange(false)}>
                                                        Filter
                                                    </Button>
                                                    <Button htmlType="button" type="primary" onClick={resetFilter}>
                                                        Reset
                                                    </Button>
                                                </Space>

                                            </Form.Item>
                                        </Form>}
                                    title="Custom Filter"
                                    trigger="click"
                                    open={visible}
                                    onOpenChange={handleVisibleChange}
                                >
                                    <Button type="primary" style={{marginBottom: 15}} >Filtro</Button>
                                </Popover>
                            </Col>
                        </Row>
                    </>
                ],
            }}
        >
                <Table size="small" {...tableProps}
                       key="id"
                       rowKey="id"
                       sticky={false}
                       scroll={{y: window.innerHeight - 293 + 'px'}}
                       pagination={false}
                       rowClassName={(record, index) => (record.isRadiato ? "red" : "")}
                >
                    <Table.Column
                        dataIndex="product"
                        key="product.codice"
                        title="Codice"
                        render={(value) => value?.codice}
                    />
                    <Table.Column
                        dataIndex="product"
                        key="product"
                        title={translate('pages.stock.product', 'Descrizione')}
                        render={(value) => value?.description}
                    />

                        <Table.Column
                            dataIndex="product"
                            key="product"
                            title={translate('pages.stock.price', 'Prezzo')}
                            render={(value) => (
                                Number(parseFloat(value?.price).toFixed(2)).toLocaleString('de', {
                                    minimumFractionDigits: 2
                                }) + " €"
                            )}
                        />

                    <Table.Column<{ id: string }>
                        title={translate('pages.users.action', 'Azioni')}
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                    onClick={() => editModalShow(record.id)}
                                />
                                <EditButton hidden={!allineamento}
                                            hideText
                                            size="small"
                                            onClick={() => editModalShow(record.id)}
                                            recordItemId={record.id}
                                />
                                <DeleteButton hidden={!allineamento}
                                              mutationMode={'undoable'}
                                              hideText
                                              size="small"
                                              recordItemId={record.id}
                                />
                            </Space>
                        )}
                    />
                </Table>
            <Modal {...editModalProps}
                    cancelText={'Annulla'}
                   okText={'Salva'}
                   title={'Dettaglio'}
                   footer={!allineamento ? <></> : [
                       <Button key="submit" type="primary" onClick={editFormProps.form?.submit}>
                           Submit
                       </Button>,
                   ]}
            >
                <Form {...editFormProps} layout="vertical">
                    <Row gutter={24}>
                        <Col span={16}>
                            <Form.Item
                                label="Prodotto"
                                name={["product","description"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly={!allineamento}/>
                            </Form.Item>
                        </Col>
                        <Col span={4} hidden={true}>
                            <Form.Item
                                label="Qta"
                                name="qta"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Magazzino"
                                name="giacenza"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Espositore"
                                name="scansiaPz"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={!allineamento}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={4} hidden={true}>
                            <Form.Item
                                label="Scansia N.ro"
                                name="scansiaNr"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Min. Espositore"
                                name="scansiaStock"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={!allineamento}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Stock Min. Riordino Kg/Conf"
                                name="stockMinKg"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={!allineamento}/>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item
                                label="Aggio %"
                                name="aggio"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={!allineamento}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Pezzi per Confezione"
                                name="nrPezziStecca"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Peso Fisc. Kg/€ Cf."
                                name="pesoFiscaleConf"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Radiato"
                                name="isRadiato"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch disabled={!allineamento}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal {...modalProps}
                   width={'40%'}
                   title={'Inserimento Articolo'}
                   cancelText={'Annulla'}
                   okText={'Conferma'}
                   onOk={saveAddArticolo}>
                <Row gutter={24}>
                    <Col span={24} style={{ marginBottom: 20}}>
                        <Input style={{
                            width: "100%"
                        }}
                               placeholder={"Codice a barre..."}
                               autoFocus={true}
                               onFocus={(e) => handleFocus(e)}
                               onChange={event => setTempSearch(event.target.value)}
                               onPressEnter={(e) => handleSearch(e)}
                               allowClear={true}
                               value={tempSearch}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Table dataSource={tempArticolo}
                               size={'small'}
                               scroll={{y: '300px'}}
                               pagination={false}>
                            <Table.Column
                                dataIndex={'description'}
                                key={'description'}
                                title={'Articolo'}
                                sorter={(a: any, b: any) => {
                                    return Number(a.key) - Number(b.key)}}
                                defaultSortOrder={'descend'}
                            />
                            <Table.Column
                                dataIndex={'codice'}
                                key={'codice'}
                                title={'Codice'}
                            />
                            <Table.Column
                                dataIndex={'actions'}
                                key={'actions'}
                                title={'Azioni'}
                                render={(_, record, index) => (
                                    <Space>
                                        <Button
                                            icon={<Icons.DeleteOutlined />}
                                            size="small"
                                            onClick={() => delTempArticolo(record)}
                                        />
                                    </Space>
                                )}
                            />
                        </Table>
                    </Col>
                </Row>
            </Modal>
        </List>

    );
};
