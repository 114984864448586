import { IResourceComponentsProps, useExport, useTranslate } from "@refinedev/core";

import {
    BooleanField,
    CreateButton,
    DeleteButton,
    EditButton,
    ExportButton,
    List,
    TagField,
    useTable,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Space, Table } from "antd";
import React from "react";
import {IDeviceType} from '../../interfaces';


export const DeviceTypeList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {tableProps} = useTable<IDeviceType>({
        resource: "device-types",
        meta: {populate: '*'}
    });

    const {triggerExport, isLoading: exportLoading} = useExport<IDeviceType>();

    return (
            <List
                title={translate('pages.deviceType.title', 'Device Type')}
                headerProps={{
                    extra: [
                        <CreateButton/>,
                        <ExportButton
                            onClick={triggerExport}
                            loading={exportLoading}
                        />
                    ],
                }}
            >
                <Table size="small" {...tableProps} rowKey="id"
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   // console.log("deviceType", record);
                               },
                           };
                       }}
                       sticky={true}

                       pagination={{
                           ...tableProps.pagination,
                           showSizeChanger: true,
                       }}>
                    {/*<Table.Column dataIndex="id" key="id" title="ID"/>*/}
                    <Table.Column
                        dataIndex="description"
                        key="description"
                        title={translate('pages.deviceType.description', 'Description')}
                    />
                    <Table.Column
                        dataIndex="isCassa"
                        key="isCassa"
                        title={translate('pages.deviceType.isCassa', 'Cassa')}
                        render={(value) => (
                            <TagField value={value} />
                        )}
                    />
                    <Table.Column
                        dataIndex="isMagazzino"
                        key="isMagazzino"
                        title={translate('pages.deviceType.isMagazzino', 'Magazzino')}
                        render={(value) => (
                            <TagField value={value} />
                        )}
                    />
                    <Table.Column<{ id: string }>
                        title={translate('pages.device.action', 'Action')}
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                                <DeleteButton
                                    mutationMode={'undoable'}
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Space>
                        )}
                    />

                </Table>
            </List>
    );
};
