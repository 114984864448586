import { IResourceComponentsProps, useExport, useTranslate } from "@refinedev/core";

import {
    BooleanField,
    CreateButton,
    DeleteButton,
    EditButton,
    ExportButton,
    List,
    TagField,
    useTable,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Space, Table } from "antd";
import React, {useContext} from 'react';
import {ICausal} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';

export const CausalList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const {tableProps} = useTable<ICausal>({
        resource: "causals",
        meta: {populate: '*'},

        filters: {
            permanent: [
                {
                    field: "seller][id]",
                    operator: 'eq',
                    value: store.id
                }
            ]
        }
    });

    const {triggerExport, isLoading: exportLoading} = useExport<ICausal>();

    return (
            <List
                title={translate('pages.group.title', 'Causali')}
                headerProps={{
                    extra: [
                        <CreateButton/>,
                        // <ExportButton
                        //     onClick={triggerExport}
                        //     loading={exportLoading}
                        // />
                    ],
                }}
            >
                <Table size="small" {...tableProps} rowKey="id"
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   // console.log("causal", record);
                               },
                           };
                       }}
                       sticky={true}

                       pagination={{
                           ...tableProps.pagination,
                           showSizeChanger: true,
                       }}>
                    {/*<Table.Column dataIndex="id" key="id" title="ID"/>*/}
                    <Table.Column
                        dataIndex="description"
                        key="description"
                        title={translate('pages.causal.description', 'Descrizione')}
                    />
                    <Table.Column
                        dataIndex="isLoad"
                        key="isLoad"
                        title={translate('pages.causal.isLoad', 'Entrata')}
                        render={(value) => (
                            <TagField value={value ? 'SI' : 'NO'} />
                        )}
                    />
                    <Table.Column
                        dataIndex="isUnLoad"
                        key="isUnLoad"
                        title={translate('pages.causal.isUnLoad', 'Uscita')}
                        render={(value) => (
                            <TagField value={value ? 'SI' : 'NO'} />
                        )}
                    />
                    <Table.Column
                        dataIndex="isTurn"
                        key="isTurn"
                        title={translate('pages.causal.isTurn', 'Turno')}
                        render={(value) => (
                            <TagField value={value ? 'SI' : 'NO'} />
                        )}
                    />
                    <Table.Column<{ id: string }>
                        title={translate('pages.users.action', 'Azioni')}
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                                <DeleteButton
                                    mutationMode={'undoable'}
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Space>
                        )}
                    />

                </Table>
            </List>
    );
};
