import React, {useContext, useEffect, useState} from 'react';
import { TagField, useModal, useTable } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Avatar, Badge, Button, Checkbox, Modal, Space, Table } from "antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import Icon from "@ant-design/icons";

import { useGetIdentity, useList } from "@refinedev/core";
import {StoreContext} from '../../contexts/StoreContext';
import {IRestock, IWareHousePosition} from '../../interfaces';
import {socket} from '../../contexts/socket';
import {toast} from 'react-toastify';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
export const RestockButton: React.FC<any> = () => {
    const {data: userIdentity} = useGetIdentity<any>();
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;

    const {show, close, modalProps} = useModal();
    const [onlyPosition, setOnlyPosition] = useState(true);
    const [dataRestock, setDataRestock] = useState<any>([]);
    const {tableQueryResult, tableProps} = useTable<IRestock>({
        resource: 'restocks',

        queryOptions: {
            enabled: true
        },
        pagination: {
            pageSize: 9999
        },
        meta: {
            populate: 'product, seller, stock'
        },
        syncWithLocation: false,
        filters: {
            defaultBehavior: "replace",
            permanent: [
                    {
                        field: 'seller][id]',
                        operator: 'eq',
                        value: store.id
                    },
                    {
                        field: 'isRiassortito',
                        operator: 'eq',
                        value: false
                    },
                {
                    field: 'qtaRiassortimento',
                    operator: 'gt',
                    value: 0
                },
                ]
        }
    });

    const {data, refetch, isFetched}  = useList<IWareHousePosition>({
        resource: "ware-house-positions",

        meta: {
            populate: 'seller,stock,stock.product,user'
        },

        queryOptions: {
            enabled: true
        },

        pagination: {
          pageSize: 999
        },

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            },
            {
                field: 'dataIn',
                operator: 'nnull',
                value: undefined
            },
            {
                field: 'dataOut',
                operator: 'null',
                value: undefined
            },
        ]
    });

    const reloadData = () => {
        setDataRestock([]);
            tableQueryResult.refetch().then( (res: any) => {
                // console.log(res);
                // console.log(data?.data);
                    for (let d of res.data.data) {
                        const pos: any = data?.data?.find((p: any) => p.stock?.product?.codice === d.product?.codice);
                        d.position = pos?.position ? pos?.position : null;
                    }
                if (onlyPosition) {
                    setDataRestock(res.data.data.filter((x: any) => x.position !== null))
                } else {
                    setDataRestock(res.data.data)
                }
            })
    }

    useEffect(() => {
        socket.on("alertRiassortimento", async (data: any, error: any) => {
            // console.log(data);
            reloadData();
            toast("E stata prelevata una stecca per riassortimento dalla posizione: " + data?.position);
        });

        socket.on("alertAddRiassortimento", async (data: any, error: any) => {
            // console.log(data);
            reloadData();
        });


        return () => {
            socket.off('alertRiassortimento');
            socket.off('alertAddRiassortimento');
        };
    }, [])

    const showModal = () => {
        reloadData();
        setOnlyPosition(false);
        setTimeout(() => {
            show();
        }, 500)
    }

    const onChange = (e: CheckboxChangeEvent) => {
        setOnlyPosition(e.target.checked);
        reloadData();
    };

    return (
        <>
            <Modal
                {...modalProps}
                width={'60%'}
                cancelText={'Annulla'} okText={'Presa Visione'} onOk={close} footer={false}>
                <Space>
                    {/*<Button onClick={reloadData}>Aggiorna</Button>*/}
                    <Checkbox onChange={onChange} defaultChecked={onlyPosition}>Visualizza Esauriti</Checkbox>
                </Space>

                <Table dataSource={dataRestock}
                       scroll={{y: window.innerHeight - 293 + 'px'}}
                       pagination={false}
                >
                    <Table.Column
                        dataIndex={'description'}
                        key={'description'}
                        title={'Descrizione'}
                        onFilter={(value, record) => false}
                        />
                    <Table.Column
                        dataIndex={'qtaRiassortimento'}
                        key={'qtaRiassortimento'}
                        title={'Qtà'}
                    />
                    <Table.Column
                        dataIndex={'position'}
                        key={'position'}
                        title={'Posizione'}
                        render={(value) => (
                            <TagField value={value ? value : 'ESAURITO'} color={value ? 'green' : 'red'} />
                        )}
                    />
                </Table>
            </Modal>
            <Badge count={dataRestock.filter((x: any) => x.position !== null).length} size="small" color={'green'}>
                <Button
                    icon={<Icons.BellOutlined />}
                    shape="circle"
                    size="small"
                    onClick={showModal} style={{ cursor: 'pointer'}}/>
            </Badge>
        </>
    );
};
