import { createContext, useState } from "react";
import {ITurn} from '../interfaces';

export const TurnContext = createContext<any[]>([]);

export const TurnProvider = (props: any) => {
    const [turn, setTurn] = useState<ITurn[]>([]);

    return <TurnContext.Provider value={[turn, setTurn]} {...props} />;
};
