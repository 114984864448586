import { IResourceComponentsProps, useExport, useTranslate } from "@refinedev/core";
import { CreateButton, DeleteButton, EditButton, ExportButton, List, useTable } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Space, Table } from "antd";
import React from "react";
import {ISeller} from '../../interfaces';

export const SellerList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {tableProps} = useTable<ISeller>({
        resource: "sellers",
        meta: {populate: '*'}
    });

    const {triggerExport, isLoading: exportLoading} = useExport<ISeller>();

    // console.log(tableProps)
    return (
            <List
                title={translate('pages.sellers.title', 'Sellers')}
                headerProps={{
                    extra: [
                        <CreateButton/>,
                        <ExportButton
                            onClick={triggerExport}
                            loading={exportLoading}
                        />
                    ],
                }}
            >
                <Table size="small" {...tableProps} rowKey="id"
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   // console.log("user", record);
                               },
                           };
                       }}
                       sticky={true}

                       pagination={{
                           ...tableProps.pagination,
                           showSizeChanger: true,
                       }}>
                    {/*<Table.Column dataIndex="id" key="id" title="ID"/>*/}
                    <Table.Column
                        dataIndex="company"
                        key="company"
                        title={translate('pages.sellers.name', 'Company')}
                    />
                    <Table.Column
                        dataIndex="name"
                        key="name"
                        title={translate('pages.sellers.name', 'Name')}
                    />
                    <Table.Column
                        dataIndex="surname"
                        key="surname"
                        title={translate('pages.sellers.surname', 'Surname')}
                    />
                    <Table.Column dataIndex="email" key="email" title={translate('pages.sellers.email', 'Email')}/>
                    <Table.Column<{ id: string }>
                        title={translate('pages.sellers.action', 'Action')}
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                                <DeleteButton
                                    mutationMode={'undoable'}
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Space>
                        )}
                    />

                </Table>
            </List>
    );
};
