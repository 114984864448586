import { toast } from "react-toastify";
import {UndoableNotification} from './undoableNotification';
import { NotificationProvider } from "@refinedev/core";
import React from 'react';

export const notificationProvider: NotificationProvider = {
    open: ({ message, key, type, undoableTimeout, cancelMutation }) => {
        // console.log(message, key, type, undoableTimeout, cancelMutation)
        if (type === "progress") {
            if (toast.isActive(key as React.ReactText)) {
                toast.update(key as React.ReactText, {
                    progress: undoableTimeout && (undoableTimeout / 10) * 2,
                    render: (
                        <UndoableNotification
                            message={message}
                            cancelMutation={cancelMutation}
                        />
                    ),
                    type: "default",
                });
            } else {
                toast(
                    <UndoableNotification
                        message={message}
                        cancelMutation={cancelMutation}
                    />,
                    {
                        toastId: key,
                        updateId: key,
                        closeOnClick: false,
                        closeButton: false,
                        autoClose: false,
                        progress: undoableTimeout && (undoableTimeout / 10) * 2,
                    },
                );
            }
        } else {
            if (toast.isActive(key as React.ReactText)) {
                toast.update(key as React.ReactText, {
                    render: (key === 'login-error' ? 'Nome Utente o Password errati' : message),
                    closeButton: true,
                    autoClose: 3000,
                    type,
                });
            } else {
                toast((key === 'login-error' ? 'Nome Utente o Password errati' : message), {
                    toastId: key,
                    type,
                });
            }
        }
    },
    close: (key) => toast.dismiss(key),
};
