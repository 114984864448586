import { IResourceComponentsProps, useCreate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Col, Form, Input, Row, Select } from "antd";
import {IGroup, IUser} from 'interfaces';
import React from "react";


export const CreateUser: React.FC<IResourceComponentsProps> = () => {

    const {formProps, saveButtonProps} = useForm<IUser>({

    });

    const save = useCreate<IUser>();

    const finish = (body: any) => {
        body.role = 1;
        body.confirmed = true;
        // console.log(body);
        save.mutate({
            resource: 'users',
            values: body
        });
    }

    const { selectProps: groupSelectProps } = useSelect<IGroup>({
        resource: "groups",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            mode: "server"
        }
    });
    return (
            <Create saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    layout="vertical"
                    onFinish={finish}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Nome"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Cognome"
                                name="surname"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Telegram ID"
                                name="telegramId"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label="UserName"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                // wrapperCol={{span: 14}}
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Stato Utente"
                                name="confirmed"
                            >
                                <Select
                                    options={[
                                        {
                                            label: "ABILITATO",
                                            value: true,
                                        },
                                        {
                                            label: "DISABILITATO",
                                            value: false,
                                        },
                                    ]}
                                    defaultValue={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Group"
                                name="group"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Select {...groupSelectProps}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Create>
    );
};
