import React, {useContext, useEffect, useState} from 'react';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Button, Popconfirm, Popover, Tooltip} from 'antd';
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';

import {
    CrudFilters,
    useCreate,
    useGetIdentity,
    useList,
    useNotification,
    useOne,
    useUpdate,
    useUpdateMany,
} from '@refinedev/core';

import {StoreContext} from '../../contexts/StoreContext';
import {ISaleMovement, IStock, ITurn} from '../../interfaces';
import {TurnContext} from '../../contexts/TurnContext';
import { useNavigate } from "react-router-dom";
import {useTable} from '@refinedev/antd';

export const TurnButton: React.FC<any> = () => {
    const { data: userIdentity } = useGetIdentity<any>();
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const [userPermission, setUserPermission] = value;
    const [turn, setTurn] = useContext(TurnContext);
    const [disableOpenTurn, setDisableOpenTurn] = useState<boolean>(false);
    const [disableCloseTurn, setDisableCloseTurn] = useState<boolean>(false);
    const { open, close } = useNotification();
    const navigate = useNavigate();

    const {data} = useList<ITurn>({
        resource: 'turns',

        queryOptions: {
            enabled: true
        },

        meta: {
            populate: 'user, seller'
        },

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store?.id
            },
            // {
            //     field: 'user][id]',
            //     operator: 'eq',
            //     value: userIdentity?.id
            // },
            {
                field: 'dataOut',
                operator: 'null',
                value: undefined
            },
        ]
    });

    const resultStock = useList<IStock>({
        resource: "stocks",
        meta: {populate: 'seller, product, product.category'},
        pagination: {
            pageSize: 999,
            mode: "server"
        },
        queryOptions: {
          enabled: false
        },
        filters: [
                {
                    field: "seller.id",
                    operator: "eq",
                    value: store.id,
                },
            ],
            sorters: [
                    {
                        field: 'product.description',
                        order: 'asc'
                    }
                ]
        });

    // console.log(resultStock);
    const addTurn = useCreate<ITurn>();
    const updateTurn = useUpdate<ITurn>();

    const openTurn = () => {
        addTurn.mutateAsync({
            resource: 'turns',
            successNotification: false,
            values: {
                description: 'Apertura Turno',
                dataIn: new Date(),
                user: userIdentity.id,
                seller: store.id
            }
        }).then( res => {
            open?.({
                type: "success",
                message: "Turno Aperto Correttamente...",
                description: 'Info',
            });
            setDisableOpenTurn(true);
            setDisableCloseTurn(false);
        })

    }

    const saleMovement = useList<ISaleMovement>({
        resource: 'sale-movements',
        queryOptions: {
            enabled: false
        },

        pagination: {
            pageSize: 10000
        },

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store?.id
            },
            {
                field: 'users][id]',
                operator: 'eq',
                value: userIdentity?.id
            },
            {
                field: 'isClosed',
                operator: 'eq',
                value: false
            },
        ]
    });

    const updateSaleMovement = useUpdateMany<ISaleMovement>();

    const updateTot = async (data: any) => {
        // GROUP BY CATEGORY
        const tempGroup: any = data.reduce((r: any, a: any) => {
            // console.log("a", a);
            // console.log("r", r);
            r[a.product.category.description] = [...(r[a.product.category.description] || []), a];
            return r;
        }, {})

        let priceCategoryEspositore = [];
        let priceCategoryMagazzino = [];

        for (let g in tempGroup) {
            priceCategoryEspositore.push({
                qta: Number(tempGroup[g].map((item: any) => item.scansiaPz).reduce((prev: number, next: number) => prev + next)).toFixed(0),
                category: g,
                tot: parseFloat(Number(tempGroup[g].map((item: any) => item.product.price * item.scansiaPz).reduce((prev: number, next: number) => prev + next)).toFixed(2))
            })
            priceCategoryMagazzino.push({
                qta: Number(tempGroup[g].map((item: any) => item.giacenza).reduce((prev: number, next: number) => prev + next)).toFixed(0),
                qtaP: Number(tempGroup[g].map((item: any) => (item.giacenza * item.nrPezziStecca)).reduce((prev: number, next: number) => prev + next)).toFixed(0),
                category: g,
                tot: parseFloat(Number(tempGroup[g].map((item: any) => (item.product.price * item.nrPezziStecca) * item.giacenza).reduce((prev: number, next: number) => prev + next)).toFixed(2))
            })
        }
        return {priceCategoryEspositore, priceCategoryMagazzino};
    }
    const closeTurn = () => {
        if (data && data.data && data.data[0].user.id === userIdentity.id) {
            resultStock.refetch().then((resStock: any) => {
                // console.log(resStock);
                if (resStock && resStock.data && resStock.data.data && resStock.data.data.length > 0) {
                    const dataStock = resStock.data.data;
                    updateTot(dataStock).then((resTotalStock: any) => {
                        // console.log(resTotalStock);
                        hidePopconfirm();
                        updateTurn.mutateAsync({
                            resource: 'turns',
                            successNotification: false,
                            id: turn.id,
                            values: {
                                description: 'Chiusura Turno',
                                dataOut: new Date(),
                                snapshotStock: resTotalStock
                            }
                        }).then( res => {
                            saleMovement.refetch().then( mvm => {
                                // console.log(mvm);
                                if (mvm && mvm.data && mvm.data?.data.length > 0) {
                                    const idsMov = mvm.data.data.map( x => x.id);
                                    // console.log(idsMov);
                                    updateSaleMovement.mutateAsync({
                                        resource: 'sale-movements',
                                        ids: idsMov,
                                        values: {
                                            isClosed: true
                                        }
                                    }).then( upd => {
                                        open?.({
                                            type: "success",
                                            message: "Turno Chiuso Correttamente...",
                                            description: 'Info',
                                        });
                                        setDisableOpenTurn(false);
                                        setDisableCloseTurn(true);
                                        navigate('turns', {state: { isOpen: true, record: turn}})
                                    })
                                }
                            })
                        })
                    })
                } else {
                    open?.({
                        type: "error",
                        message: "Si è verificato un errore nello Snapshot Stock!",
                        description: 'Info',
                    });
                }
            })
        } else {
            open?.({
                type: "success",
                message: "Non puoi chiudere il Turno perchè risulta aperto dall'utente: " + data?.data[0].user.surname + ' ' + data?.data[0].user.surname,
                description: 'Info',
            });
        }

    }

    useEffect(() => {
        if (data && data.data.length > 0 && !data.data[0].dataOut) {
            setDisableOpenTurn(true);
            setDisableCloseTurn(false);
            setTurn(data.data[0]);
        } else {
            setDisableCloseTurn(true);
            setTurn(data?.data[0]);
        }
    })

    const [openConfirm, setOpenConfirm] = useState(false);
    const showPopconfirm = () => {
        setOpenConfirm(true);
    };

    const hidePopconfirm = () => {
        setOpenConfirm(false);
    };

    const handleCancel = () => {
        // console.log('Clicked cancel button');
        setOpenConfirm(false);
    };

    const onCheck = async () => {
        showPopconfirm();
    };

    return (
        <>
            <Tooltip title={'Apri il Turno'} placement={'top'}>
                <Button style={{
                    color: 'white',
                    backgroundColor: disableCloseTurn ? 'green' : 'gray',
                    marginRight: 10, width: 30, marginBottom: 10 }}
                        disabled={disableOpenTurn} onClick={openTurn}
                        icon={<Icons.ClockCircleFilled />}
                >
                </Button>
            </Tooltip>
            <Tooltip title={'Chiudi il Turno'} placement={'right'}>
                <Popconfirm
                    title="Attenzione"
                    open={openConfirm}
                    description="Sei Sicuro di voler confermare la chiusura Turno?"
                    onConfirm={closeTurn}
                    cancelText={'No'}
                    okText={'Si! Sono Sicuro'}
                    onCancel={handleCancel}
                >
                    <Button style={{
                        color: 'white',
                        backgroundColor: disableCloseTurn ? 'grey' : 'red',
                        marginRight: 0, width: 30}} disabled={disableCloseTurn} onClick={onCheck}
                            icon={<Icons.ClockCircleOutlined />}
                    >
                    </Button>
                </Popconfirm>
            </Tooltip>
        </>
    );
};
