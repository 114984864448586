import React, {useContext, useState} from 'react';
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Form, Input, Select, Row, Col, Switch, DatePicker, InputNumber} from 'antd';

import {ICausal, IPatent, ISeller} from 'interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import {PatentCreate} from './create';
import dayjs from 'dayjs';

export const PatentEdit: React.FC<IResourceComponentsProps> = () => {
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const {formProps, saveButtonProps, queryResult} = useForm<IPatent>({
    });

    const { selectProps: sellerSelectProps } = useSelect<ISeller>({
        resource: "sellers",
        optionValue: 'id',
        optionLabel: 'description',
        defaultValue: queryResult?.data?.data?.seller?.id,

        pagination: {
            mode: "server"
        }
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Row gutter={24}>
                    <Col span={store.id ? 12 : 6}>
                        <Form.Item
                            label="Ragione Sociale"
                            name="company"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6} hidden={store?.id}>
                        <Form.Item
                            label="Seller"
                            name="seller"
                            rules={[
                                {
                                    required: !store?.id,
                                },
                            ]}
                        >
                            <Select {...sellerSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Licenza"
                            name="license"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Aggio %"
                            name="aggio"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Indirizzo"
                            name="address"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Città"
                            name="city"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Cap"
                            name="zip"
                            rules={[
                                {
                                    required: false,
                                    max: 5,
                                    len: 5
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Data Scadenza"
                            name="expireDate"
                            getValueProps={(value) => ({
                                value: value ? dayjs(value) : '',
                            })}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker style={{width: '100%'}} format={'DD-MM-YYYY'}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
