import React, {useState} from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Form, Input, Select, Row, Col, Switch } from "antd";

import {IGroup} from 'interfaces';
export const GroupEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps} = useForm<IGroup>({
        meta: {populate: ["clientes"]},
    });


    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="isAdmin"
                            name="isAdmin"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="isUser"
                            name="isUser"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="isOperator"
                            name="isOperator"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
