import { IResourceComponentsProps, useCreate, useDelete, useModal, useShow } from "@refinedev/core";

import {
    Create,
    DeleteButton,
    EditButton,
    useForm,
    useModalForm,
    useSelect,
    useTable,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
    AutoComplete,
    Button,
    Col,
    Form,
    Input,
    Modal,
    Popover,
    Row,
    Select,
    Space, Switch,
    Table,
    Typography,
} from 'antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import Icon from "@ant-design/icons";

import {IDevice, IGroup, ISeller, IUser} from 'interfaces';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
const { Title } = Typography;


export const SellerCreate: React.FC<IResourceComponentsProps> = () => {

    const {formProps, saveButtonProps} = useForm<ISeller>({
        resource: 'sellers',
        warnWhenUnsavedChanges: false,
        meta: {populate: ['users_permission_users']},
    });

    const save = useCreate<ISeller>();
    const navigate = useNavigate();
    const finish = (body: any) => {
        // console.log('Users', users);
        body.users_permissions_users = {connect : users};
        body.description = body.company;
        // console.log('Body', body);
        save.mutateAsync({
            resource: 'sellers',
            values: body
        }).then( res => {
            navigate('/sellers');
        });
    }


    const { tableProps } = useTable<IUser>({
        resource: "users"
    });

    const onSelect = (data: string) => {
        // console.log('onSelect', data);
    };

    const [users, setUsers] = useState<any>([]);

    const { visible, show, close } = useModal();

    const { selectProps: usersSelectProps, queryResult: userSelectResult } = useSelect<IUser>({
        resource: "users",
        meta: {populate: 'group'},
        optionValue: 'id',
        optionLabel: 'email',

        pagination: {
            mode: "server"
        }
    });

    const showUsers = () => {
        if (visible) {
            close()
        } else {
            show()
        }

    };

    // Create Modal
    const {
        modalProps: createModalProps,
        formProps: createFormProps,
        show: createModalShow,
        queryResult: userCreated,
    } = useModalForm<IUser>({
        resource: "users",
        action: "create",
        redirect: false,
        onMutationSuccess: (data, variables, context) => {
            // console.log(data, variables, context)
            const d = [];
            d.push(data.data);
            setUsers(d);
        }
    });

    // Edit Modal
    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
    } = useModalForm<IUser>({
        resource: "users",
        action: "edit",
        warnWhenUnsavedChanges: true
    });

    const { selectProps: groupSelectProps } = useSelect<IGroup>({
        resource: "groups",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: deviceSelectProps } = useSelect<IDevice>({
        resource: "devices",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            mode: "server"
        }
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const [selectedUSer, setSelectedUser] = useState<any>([]);
    const handleOk = () => {
        setIsModalOpen(false);
        // console.log(selectedUSer)
        const u = userSelectResult.data?.data.find((u: any) => u.id === Number(selectedUSer))
        // console.log(u);
        setUsers((users: any) => [...users, userSelectResult.data?.data.find((u: any) => u.id === Number(selectedUSer))])
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onDeleteUser = (data: any) => {
        // const d = queryResult?.data?.data.users_permissions_users.filter(item => item.id !== data);
        // setUsersTable(d);
        // setUserDisconnect([queryResult?.data?.data.users_permissions_users.find((obj) => obj.id === data)]);

    }

    return (
            <Create saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    layout="vertical"
                    onFinish={finish}
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Company"
                                name="company"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Surname"
                                name="surname"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={4}>
                            <Form.Item
                                label="City"
                                name="city"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Address"
                                name="address"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Zip"
                                name="zip"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Country"
                                name="country"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Phone"
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Mobile"
                                name="mobile"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Piva"
                                name="piva"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Cf"
                                name="cf"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Device"
                                name={["devices","id"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select {...deviceSelectProps} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Telegram ID"
                                name="telegramId"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Crea Stock Default"
                                name="isStockDefault"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row gutter={24}>
                    <Col span={24}>
                        <Popover title="Show Users">
                            <Button type={'primary'} icon={<Icons.OrderedListOutlined/>} onClick={showUsers} style={{ marginBottom: 10, marginRight: 10}} />
                        </Popover>
                        <Popover title="Create Users">
                            <Button type={'default'} icon={<Icons.PlusOutlined/>} onClick={() => createModalShow()} style={{ marginBottom: 10, marginRight: 10}}/>
                        </Popover>
                        <Popover title="Add Users">
                            <Button type={'default'} icon={<Icons.UserOutlined/>} onClick={showModal} style={{ marginBottom: 10}}/>
                        </Popover>
                        <Modal {...createModalProps}>
                            <Form {...createFormProps} layout="vertical">
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item hidden={true}
                                                   label="Role"
                                                   name="role"
                                                   initialValue={1}
                                        >
                                            <Input value={1}/>
                                        </Form.Item>
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={6}>
                                        <Form.Item
                                            label="UserName"
                                            name="username"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            // wrapperCol={{span: 14}}
                                            label="Password"
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="Stato Utente"
                                            name="confirmed"
                                        >
                                            <Select
                                                options={[
                                                    {
                                                        label: "ABILITATO",
                                                        value: true,
                                                    },
                                                    {
                                                        label: "DISABILITATO",
                                                        value: false,
                                                    },
                                                ]}
                                                defaultValue={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="Group"
                                            name="group"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Select {...groupSelectProps}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal>
                        <Modal {...editModalProps}>
                            <Form {...editFormProps} layout="vertical">
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={6}>
                                        <Form.Item
                                            label="UserName"
                                            name="username"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            // wrapperCol={{span: 14}}
                                            label="Password"
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="Stato Utente"
                                            name="confirmed"
                                        >
                                            <Select
                                                options={[
                                                    {
                                                        label: "ABILITATO",
                                                        value: true,
                                                    },
                                                    {
                                                        label: "DISABILITATO",
                                                        value: false,
                                                    },
                                                ]}
                                                defaultValue={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="Group"
                                            name="group"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Select {...groupSelectProps}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal>
                        <Modal title="Select User" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Select
                                        allowClear={true}
                                        placeholder="Select User"
                                        onSelect={(u) => setSelectedUser(u)}
                                        style={{ width: '100%' }}
                                        {...usersSelectProps} />
                                </Col>
                            </Row>
                        </Modal>
                        {visible && (
                            <Table
                                dataSource={users}
                                key={Math.random()}>
                                <Table.Column
                                    dataIndex="id"
                                    title="ID"
                                    render={(value) => {
                                        return (
                                            value
                                        );
                                    }}
                                />
                                <Table.Column
                                    dataIndex="email"
                                    title="Email"
                                    render={(value) => {
                                        return (
                                            value
                                        );
                                    }}
                                />
                                <Table.Column
                                    dataIndex="username"
                                    title="Username"
                                    render={(value) => {
                                        return (
                                            value
                                        );
                                    }}
                                />
                                <Table.Column<{ id: string }>
                                    title="Actions"
                                    render={(_, record) => (
                                        <Space>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                onClick={() => editModalShow(record.id)}
                                            />
                                            <Button
                                                icon={<Icons.DeleteOutlined />}
                                                size="small"
                                                onClick={() => onDeleteUser(record.id)}
                                            />
                                        </Space>
                                    )}
                                />
                            </Table>
                        )}
                    </Col>
                </Row>
            </Create>
    );
};
