import React, {useContext, useEffect, useState} from 'react';
import { useSelect } from "@refinedev/antd";
import { Select, Space, Typography } from "antd";

import {IDevice, ISeller, IUser} from 'interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';
import { useGetIdentity, useOne, useUpdate } from "@refinedev/core";
import {DeviceContext} from '../../contexts/DeviceContext';
import {useLocation} from 'react-router-dom';
const { Text, Title } = Typography;
type SelectProps = {
    onSelect: () => void;
};

export const DeviceSelect: React.FC<SelectProps> = ({ onSelect }) => {
    const { data: identity } = useGetIdentity<{ id: number; fullName: string}>();
    const [device, setDevice] = useContext(DeviceContext);
    const userDevice = sessionStorage.getItem('device') || '';
    const utilityHelper = UtilityHelper(API_URL + "/api");
    const location = useLocation();

    const { selectProps: deviceSelectProps, queryResult } = useSelect<ISeller>({
        resource: "sellers",

        meta: {
            populate: ["devices", "devices.device_type"]
        },

        filters: [
            {
              operator: 'and',
              value: [
                  {
                      field: 'id',
                      operator: 'eq',
                      value: utilityHelper.sellerId()
                  }
              ]
            }
        ],

        pagination: {
            mode: "server"
        }
    });

    useEffect(() => {
        if (!location.pathname.includes('stocks')) {
            localStorage.removeItem('pass');
        }
        if (!location.pathname.includes('restocks')) {
            localStorage.removeItem('passRestock');
        }
    }, [location]);

    useEffect(() => {
        const s: any = sessionStorage.getItem('device');
        if (s === 'undefined') {
            setDevice(null);
        } else {
            setDevice(JSON.parse(s)?.id)
        }
    }, []);

    const userUpdate = useUpdate<IUser>();
    const handleChange = (selectedValue: string) => {
        setDevice(selectedValue);
        const selectedDevice = options?.filter(x => x.id === Number(selectedValue)) || [];
        sessionStorage.setItem('device', JSON.stringify(selectedDevice[0]));
        userUpdate.mutate({
            resource: "users",
            id: Number(identity?.id),
            values: {
                device: selectedValue ? selectedValue : null
            },
            successNotification: (data, values, resource) => {
                return {
                    message: 'Dispositivo Impostato Correttamente',
                    description: "Success with no errors",
                    type: "success",
                };
            },
        })
    };

    const options = queryResult?.data?.data[0]?.devices;

    return (
            <Select
                defaultValue={userDevice && userDevice !== 'undefined' ? JSON.parse(userDevice).description : null}
                style={{ width: 150 }}
                onChange={handleChange}
                onSelect={onSelect}
                allowClear={true}
                placeholder={'Seleziona Dispositivo Cassa...'}
            >
                { options?.filter((x: any) => x.device_type.isCassa).map((option: any) => {
                    return(
                        <Select.Option key={option.id} value={option.id}>
                            {option.description}
                        </Select.Option>
                    )})
                }
            </Select>
    );
};
