import React, {useContext, useState} from 'react';
import { IResourceComponentsProps, useUpdate } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Form, Input, Select, Row, Col, Switch } from "antd";

import {IDocType, IParameter, ISeller} from 'interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import { useNavigate } from "react-router-dom";
export const ParameterEdit: React.FC<IResourceComponentsProps> = () => {
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const navigate = useNavigate();
    const {formProps, saveButtonProps, queryResult, id} = useForm<IParameter>({
        resource: "parameters",
        warnWhenUnsavedChanges: false,
        meta: {
            populate: "*"
        }
    });

    const save = useUpdate();

    const { selectProps: usersSelectProps } = useSelect<IDocType>({
        resource: "doc-types",
        optionValue: 'id',
        optionLabel: 'description',
        defaultValue: queryResult?.data?.data?.docType?.id,

        filters: [
            {
                field: "seller][id]",
                operator: "eq",
                value: store.id
            }
        ],

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: sellerSelectProps } = useSelect<ISeller>({
        resource: "sellers",
        optionValue: 'id',
        optionLabel: 'description',
        defaultValue: queryResult?.data?.data?.seller?.id,

        pagination: {
            mode: "server"
        }
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
                onFinish={(data: any) => {
                    // console.log(data);
                    save.mutateAsync({
                        resource: "parameters",
                        id: Number(id),
                        values: {
                            progressive: data.progressive,
                            doc_type: data.doc_type.id,
                            seller: store.id
                            }
                        }).then( res => {
                            navigate('/prm/parameters');
                    })
                    }
                }
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Progressive"
                            name="progressive"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={store.id ? 12 : 6}>
                        <Form.Item
                            label="Type Doc"
                            name={["doc_type", "id"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...usersSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={6} hidden={store?.id}>
                        <Form.Item
                            label="Seller"
                            name={["seller", "id"]}
                            rules={[
                                {
                                    required: !store?.id,
                                },
                            ]}
                        >
                            <Select {...sellerSelectProps} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
