import React, {useState} from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Form, Input, Select, Row, Col } from "antd";

import {IGroup, ISeller, IUser} from 'interfaces';

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps, queryResult} = useForm<IUser>({
        meta: {populate: ["group"]},
    });

    const { selectProps: groupSelectProps } = useSelect<IGroup>({
        resource: "groups",
        optionValue: 'id',
        optionLabel: 'description',
        defaultValue: queryResult?.data?.data?.group?.id,

        pagination: {
            mode: "server"
        }
    });
    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={6}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Nome"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Cognome"
                            name="surname"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Telegram ID"
                            name="telegramId"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={6}>
                        <Form.Item
                            label="UserName"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            // wrapperCol={{span: 14}}
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Stato Utente"
                            name="confirmed"
                        >
                            <Select
                                options={[
                                    {
                                        label: "ABILITATO",
                                        value: true,
                                    },
                                    {
                                        label: "DISABILITATO",
                                        value: false,
                                    },
                                ]}
                                defaultValue={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Group"
                            name={["group", "id"]}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Select {...groupSelectProps}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
