import { IResourceComponentsProps, useExport, useTranslate } from "@refinedev/core";

import {
    BooleanField,
    CreateButton,
    DeleteButton,
    EditButton,
    ExportButton,
    List,
    useTable,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Space, Table } from "antd";
import React, {useContext} from 'react';
import {IUser} from "../../interfaces";

export const UsersList: React.FC<IResourceComponentsProps> = (props, context) => {
    const translate = useTranslate();
    const {tableProps} = useTable<IUser>({
        resource: "users",
        meta: {populate: '*'}
    });

    const {triggerExport, isLoading: exportLoading} = useExport<IUser>();
    return (
            <List
                title={translate('pages.users.title', 'Users')}
                headerProps={{
                    extra: [
                        <CreateButton/>,
                        <ExportButton
                            onClick={triggerExport}
                            loading={exportLoading}
                        />
                    ],
                }}
            >
                <Table size="small" {...tableProps} rowKey="id"
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   // console.log("user", record);
                               },
                           };
                       }}
                       sticky={true}

                       pagination={{
                           ...tableProps.pagination,
                           showSizeChanger: true,
                       }}>
                    {/*<Table.Column dataIndex="id" key="id" title="ID"/>*/}
                    <Table.Column dataIndex="email" key="email" title={translate('pages.users.email', 'Email')}/>
                    <Table.Column
                        dataIndex="confirmed"
                        title={translate('pages.users.status', 'Status')}
                        render={(value) => (
                            <BooleanField
                                value={value === true}
                                trueIcon={<Icons.CheckCircleOutlined/>}
                                falseIcon={<Icons.CloseCircleOutlined/>}
                            />
                        )}
                    />
                    <Table.Column<{ id: string }>
                        title={translate('pages.users.action', 'Action')}
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                                <DeleteButton
                                    mutationMode={'undoable'}
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Space>
                        )}
                    />

                </Table>
            </List>
    );
};
