import { IResourceComponentsProps, useCreate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Col, Form, Input, Row, Select, Switch } from "antd";
import {IDocType, IParameter, ISeller, IWhareHouseMovement} from 'interfaces';
import React, {useContext} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import { useNavigate } from "react-router-dom";


export const ParameterCreate: React.FC<IResourceComponentsProps> = () => {
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const navigate = useNavigate();
    const {formProps, saveButtonProps} = useForm<IParameter>({
        warnWhenUnsavedChanges: false
    });

    const { selectProps: usersSelectProps } = useSelect<IDocType>({
        resource: "doc-types",
        optionValue: 'id',
        optionLabel: 'description',

        filters: [
            {
                field: "seller][id]",
                operator: "eq",
                value: store.id
            }
        ],

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: sellerSelectProps } = useSelect<ISeller>({
        resource: "sellers",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            mode: "server"
        }
    });

    const parameter = useCreate<IParameter>();
    const onFinish = (data: any) => {
        // console.log(data);
        parameter.mutate({
            resource: "parameters",
            values: {
                doc_type: data.doc_type,
                progressive: data.progressive,
                seller: store.id,
            }
        })
        navigate('/prm/parameters');
    }

    return (
            <Create saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Progressive"
                                name="progressive"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={store.id ? 12 : 6}>
                            <Form.Item
                                label="Type Doc"
                                name={["doc_type", "id"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select {...usersSelectProps} />
                            </Form.Item>
                        </Col>
                        <Col span={6} hidden={store?.id}>
                            <Form.Item
                                label="Seller"
                                name={["seller", "id"]}
                                rules={[
                                    {
                                        required: !store?.id,
                                    },
                                ]}
                            >
                                <Select {...sellerSelectProps} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Create>
    );
};
