import { AuthBindings } from "@refinedev/core";
import { AuthHelper } from "@refinedev/strapi-v4";
import {TOKEN_KEY, API_URL, FRONT_URL} from './constants';
import axios from "axios";
import {UtilityHelper} from './helper/utility';
import {setupCache} from 'axios-cache-adapter';
import {message} from 'antd';

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000
})

export const axiosInstance = axios.create({
  adapter: cache.adapter
});
const strapiAuthHelper = AuthHelper(API_URL + "/api");

export const authProvider: AuthBindings = {
  login: async ({ email, password }) => {
    const { data, status } = await strapiAuthHelper.login(email, password);
    if (status === 200) {
      localStorage.setItem(TOKEN_KEY, data.jwt);
      // set header axios instance
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${data.jwt}`,
      };
      return {
        success: true,
        redirectTo: "/"
      }
    }
    return {
      success: true,
      error: {
        message: "Login Error",
        name: "Nome Utente o Password Errati!"
      }
    }
  },
  logout: async () => {
    localStorage.removeItem(TOKEN_KEY);
    return {
      success: true,
      redirectTo: "/"
    }
  },
  onError: async (error) => {
    if (error.status === 401 || error.status === 403) {
      return {
        redirectTo: "/login",
        logout: true,
        error: error
      }
    }
    return {}
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
      };
      return {
        authenticated: true
      }
    }
    return {
      authenticated: false,
      redirectTo: "/login",
      logout: true,
      error: {
        message: "Check Failed",
        name: "User not Found"
      }
    }
  },
  forgotPassword: async ({ email }) => {
    // You can handle the reset password process according to your needs.
    await  axios.post(API_URL + '/api/auth/forgot-password', {
      email: email
    });
    message.info('Controlla la tua casella di posta!');
    setTimeout(() => {
      window.open(FRONT_URL + '/login', '_self');
    },  3000)
    return {
      success: true
    };
  },
  updatePassword: async ({code, password, confirmPassword}) => {
    console.log(code, password)
    // You can handle the reset password process according to your needs.
    axios.post(API_URL + '/api/auth/reset-password', {
      code: code,
      password: password,
      passwordConfirmation: confirmPassword
    }).then( res => {
      console.log(res);
      message.info('Password Reimpostata Correttamente!');
      setTimeout(() => {
        // window.open( FRONT_URL + '/login', '_self');
      },  3000)

    })
    return {
      success: true,
    };
  },
  getPermissions: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      const utilityHelper = UtilityHelper(API_URL + "/api");
      const user = await utilityHelper.permission();
      return Promise.resolve(user);
    }
    return null;
  },
  getIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    const { data, status } = await strapiAuthHelper.me(token);
    if (status === 200) {
      const { id, username, email } = data;
      return Promise.resolve({
        id,
        username,
        email,
      });
    }
    return null;
  },
};
