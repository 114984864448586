import React, {useContext, useState} from 'react';
import { IResourceComponentsProps, useCreate, useList } from "@refinedev/core";

import {
    Edit,
    useForm,
    useSelect,
    useTable,
    TagField,
    ShowButton,
    EditButton,
    DeleteButton,
    CloneButton,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
    Form,
    Input,
    Select,
    Row,
    Col,
    Switch,
    Table,
    Popover,
    Button,
    Space,
    Divider,
} from "antd";

import {IDevice, IDeviceType, ISeller, IStatusWareHouse, IWareHousePosition} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';


export const DeviceEdit: React.FC<IResourceComponentsProps> = () => {
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const {formProps, saveButtonProps, queryResult} = useForm<IDevice>({
        meta: {populate: "*"},
    });

    const { selectProps: deviceSelectProps } = useSelect<IDeviceType>({
        resource: "device-types",
        optionValue: 'id',
        optionLabel: 'description',
        defaultValue: queryResult?.data?.data?.device_type?.id,

        pagination: {
            mode: "server"
        }
    });

    // const { selectProps: sellerSelectProps } = useSelect<ISeller>({
    //     resource: "sellers",
    //     optionValue: 'id',
    //     optionLabel: 'description',
    //     defaultValue: queryResult?.data?.data?.seller?.id
    // });

    const {tableProps, tableQueryResult} = useTable<IStatusWareHouse>({
        resource: "status-ware-houses",
        meta: {populate: 'seller, device'},

        queryOptions: {
            enabled: true
        },

        filters: {
            permanent: [
                    {
                        field: "seller.id",
                        operator: "eq",
                        value: store.id,
                    },
                    {
                        field: "device][id]",
                        operator: 'eq',
                        value: queryResult?.data?.data?.id
                    }
                ]
        }
    });

    const addModules = useCreate<IStatusWareHouse>();
    const addModule = () => {
        const maxValue: any = tableQueryResult.data?.data.reduce((prev, current) => (prev.module > current.module) ? prev : current);
        // console.log(maxValue)
        addModules.mutateAsync({
            resource: 'status-ware-houses',
            values: {
                device: queryResult?.data?.data?.id,
                seller: store.id,
                module:  Number(maxValue.module) +  1
            }
        })
    }

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="Dispositivo"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item
                            label="Numero Dispositivo"
                            name="deviceNumber"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Seriale"
                            name="serial"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Indirizzo IP"
                            name="ip"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="Numero Moduli"
                            name="deviceCount"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="Ver. Soft"
                            name="versioneSoftware"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input placeholder={"1.0"} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Device Type"
                            name={["device_type", "id"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...deviceSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Update?"
                            name="isUpdate"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    {/*<Col span={8}>*/}
                    {/*    <Form.Item*/}
                    {/*        label="Seller"*/}
                    {/*        name={["seller", "id"]}*/}
                    {/*        rules={[*/}
                    {/*            {*/}
                    {/*                required: true,*/}
                    {/*            },*/}
                    {/*        ]}*/}
                    {/*    >*/}
                    {/*        <Select {...sellerSelectProps} />*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                </Row>
            </Form>
            <Row gutter={24}>
                <Col span={8}>
                    <Button type={'primary'} onClick={addModule}>Aggiungi Modulo</Button>
                </Col>
            </Row>
            <Divider></Divider>
            <Row gutter={24}>
                <Col span={24}>
                    <Table {...tableProps}>
                        <Table.Column
                            dataIndex="device"
                            key="device"
                            title="Master"
                            align={'center'}
                            // render={(value) => String.fromCharCode(value + 65)}
                            render={(value) => <TagField color={'blue'} value={value.deviceNumber} />}
                        />
                        <Table.Column
                            dataIndex="module"
                            key="module"
                            title="Modulo"
                            align={'center'}
                            // render={(value) => String.fromCharCode(value + 65)}
                            render={(value) => <TagField color={'yellow'} value={String.fromCharCode(value + 65)} />}
                        />
                        <Table.Column
                            dataIndex="col1"
                            key="col1"
                            align={'center'}
                            title={'Col1'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} />
                           }
                        />
                        <Table.Column
                            dataIndex="col2"
                            key="col2"
                            align={'center'}
                            title={'Col2'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} />
                            }
                        />
                        <Table.Column
                            dataIndex="col3"
                            key="col3"
                            align={'center'}
                            title={'Col3'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} />
                            }
                        />
                        <Table.Column
                            dataIndex="col4"
                            key="col4"
                            align={'center'}
                            title={'Col4'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} />
                            }
                        />
                        <Table.Column
                            dataIndex="col5"
                            key="col5"
                            align={'center'}
                            title={'Col5'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} />
                            }
                        />
                        <Table.Column
                            dataIndex="col6"
                            key="col6"
                            align={'center'}
                            title={'Col6'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} />
                            }
                        />
                        <Table.Column
                            dataIndex="col7"
                            key="col7"
                            align={'center'}
                            title={'Col7'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} />
                            }
                        />
                        <Table.Column
                            dataIndex="col8"
                            key="col8"
                            align={'center'}
                            title={'Col8'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} />
                            }
                        />
                        <Table.Column
                            dataIndex="col9"
                            key="col9"
                            align={'center'}
                            title={'Col9'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} />
                            }
                        />
                        <Table.Column
                            dataIndex="col10"
                            key="col10"
                            align={'center'}
                            title={'Col10'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} />
                            }
                        />
                        <Table.Column
                            dataIndex="col11"
                            key="col11"
                            align={'center'}
                            title={'Col11'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} />
                            }
                        />
                        <Table.Column
                            dataIndex="col12"
                            key="col12"
                            align={'center'}
                            title={'Col12'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} />
                            }
                        />
                        <Table.Column
                            dataIndex="col13"
                            key="col13"
                            align={'center'}
                            title={'Col13'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} />
                            }
                        />
                        <Table.Column
                            dataIndex="col14"
                            key="col14"
                            align={'center'}
                            title={'Col14'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} />
                            }
                        />
                        <Table.Column
                            dataIndex="col15"
                            key="col15"
                            align={'center'}
                            title={'Col15'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} />
                            }
                        />
                        <Table.Column
                            dataIndex="col16"
                            key="col16"
                            align={'center'}
                            title={'Col16'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} />
                            }
                        />
                        <Table.Column<{ id: string }>
                            title={'Azioni'}
                            dataIndex="actions"
                            render={(_, record) => (
                                <Space>
                                    <DeleteButton
                                        resource={'status-ware-houses'}
                                        mutationMode={'undoable'}
                                        hideText
                                        size="small"
                                        recordItemId={record.id}
                                    />
                                </Space>
                            )}
                        />
                    </Table>
                </Col>
            </Row>

        </Edit>
    );
};
