import { IResourceComponentsProps, useCreate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Col, Form, Input, Row, Select, Switch } from "antd";
import {ICausal, IDocType, ISeller} from 'interfaces';
import React, {useContext} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import { useNavigate } from "react-router-dom";
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';


export const CausalCreate: React.FC<IResourceComponentsProps> = () => {
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const navigate = useNavigate();
    const utilityHelper = UtilityHelper(API_URL + "/api");
    const {formProps, saveButtonProps} = useForm<ICausal>({
        resource: 'causals',
        warnWhenUnsavedChanges: false

    });

    const { selectProps: sellerSelectProps } = useSelect<ISeller>({
        resource: "sellers",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            mode: "server"
        }
    });

    const save = useCreate<ICausal>();

    const onFinish = (value: any) => {
        // console.log(value)
        utilityHelper.permission().then( r => {
            // console.log(r);
            if (r !== 'admin') {
                value.seller = store.id;
            }
            save.mutateAsync({
                resource: 'causals',
                values: value
            }).then( res => {
                navigate('/prm/causals');
            })
        })

    }

    return (
            <Create saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={store.id ? 11 : 5}>
                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={6} hidden={store?.id}>
                            <Form.Item
                                label="Seller"
                                name="seller"
                                rules={[
                                    {
                                        required: !store?.id,
                                    },
                                ]}
                            >
                                <Select {...sellerSelectProps} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Acronimo"
                                name="acronimo"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                label="Entrata"
                                name="isLoad"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                label="Uscita"
                                name="isUnLoad"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                label="Turno"
                                name="isTurn"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Create>
    );
};
