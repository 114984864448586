import { IResourceComponentsProps, useCreate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Col, Form, Input, Row, Select, Switch } from "antd";
import {ICategory} from 'interfaces';
import React from "react";


export const CategoryCreate: React.FC<IResourceComponentsProps> = () => {

    const {formProps, saveButtonProps} = useForm<ICategory>({
    });

    return (
            <Create saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    layout="vertical"
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="isTabacco"
                                name="isTabacco"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="isFood"
                                name="isFood"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="isGeneric"
                                name="isGeneric"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="isWine"
                                name="isWine"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Create>
    );
};
