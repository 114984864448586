import {
    CrudFilters,
    IResourceComponentsProps, useList,
    useTranslate,
} from '@refinedev/core';
import {
    DeleteButton,
    EditButton,
    List,
    ShowButton, TagField,
    useModalForm, useSelect,
    useTable
} from '@refinedev/antd';
import {Button, Col, Dropdown, Form, Input, Modal, Popover, Row, Select, SelectProps, Space, Switch, Table} from 'antd';
import {ICategory, IProduct, IStock, IWareHousePosition} from 'interfaces';
import React, {useContext, useEffect, useState} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import {useLocation} from 'react-router-dom';
import * as Icons from '@ant-design/icons';


export const EspositoreStock: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const state: any = useLocation();
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;

    const {tableProps, searchFormProps, setFilters, filters, tableQueryResult, setSorters} = useTable<IStock>({
        resource: "stocks",
        meta: {populate: 'seller, product, product.category'},

        onSearch: (params: any) => {
            const filters: CrudFilters = [];

            filters.push(
                {
                    operator: "or",
                    value: [
                        {
                            field: "product][barcodes][code]",
                            operator: "eq",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][barcode_packages][code]",
                            operator: "eq",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][description]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][codice]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][category][description]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                    ]
                },
                {
                    field: "product][category][id]",
                    operator: "contains",
                    value: (params?.category) ? params?.category : ''
                }
            );

            return filters;
        },
        pagination: {
            pageSize: 999,
            mode: "server"
        },

        filters: {
            permanent: [
                {
                    field: "seller.id",
                    operator: "eq",
                    value: store.id,
                },
                {
                    field: "scansiaPz",
                    operator: "gt",
                    value: 0
                }
            ]
        },

        sorters: {
            permanent: [
                {
                    field: 'product.description',
                    order: 'asc'
                }
            ]
        }
    });

    const wareHousePosition = useList<IWareHousePosition>({
        resource: "ware-house-positions",
        meta: {populate: 'seller, stock, stock.product, stock.product.category'},

        queryOptions: {
            enabled: false
        },

        pagination: {
            pageSize: 9999,
            mode: "server"
        },

        filters: [
            {
                field: "seller.id",
                operator: "eq",
                value: store.id,
            },
            {
                field: "stock][giacenza]",
                operator: 'gt',
                value: 0
            },
            {
                field: "dataOut",
                operator: 'null',
                value: undefined
            }
        ],

        sorters: [
            {
                field: 'stock.description',
                order: 'asc'
            }
        ]
    });

    const [detailCategoryEspositore, setDetailCategoryEspositore] = useState<any>([]);
    const [totEspositore, setTotEspositore] = useState<number>(0);
    const updateTot = () => {
        // GROUP BY CATEGORY
        const tempGroup: any = tableQueryResult.data?.data.reduce((r: any, a: any) => {
            // console.log("a", a);
            // console.log("r", r);
            r[a.product.category.description] = [...(r[a.product.category.description] || []), a];
            return r;
        }, {})

        // console.log(tempGroup)
        let priceCategoryEspositore = [];
        let priceCategoryMagazzino = [];

        for (let g in tempGroup) {
            priceCategoryEspositore.push({
                qta: Number(tempGroup[g].map((item: any) => item.scansiaPz).reduce((prev: number, next: number) => prev + next)).toFixed(0),
                category: g,
                tot: parseFloat(Number(tempGroup[g].map((item: any) => item.product.price * item.scansiaPz).reduce((prev: number, next: number) => prev + next)).toFixed(2))
            })
            priceCategoryMagazzino.push({
                qta: Number(tempGroup[g].map((item: any) => item.giacenza).reduce((prev: number, next: number) => prev + next)).toFixed(0),
                category: g,
                tot: parseFloat(Number(tempGroup[g].map((item: any) => (item.product.price * item.nrPezziStecca) * item.giacenza).reduce((prev: number, next: number) => prev + next)).toFixed(2))
            })
        }
        setDetailCategoryEspositore(priceCategoryEspositore);
    }

    useEffect(() => {
        if (tableQueryResult && tableQueryResult.data && tableQueryResult.data.data.length > 0) {
            setTotEspositore(parseFloat(Number(tableQueryResult.data?.data.map(item => item.product?.price * item?.scansiaPz).reduce((prev, next) => prev + next)).toFixed(2)));
        } else {
            setTotEspositore(0);
        }
        if (state && state.state && state.state.type === 3) {
            // handleStock();
        }
    }, [tableQueryResult.data?.data?.length]);

    useEffect(() => {
        updateTot();
    }, [tableQueryResult.isFetching])

    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
    } = useModalForm<IStock>({
        action: "edit",
        resource: "stocks",
        meta: {populate: '*'},
        warnWhenUnsavedChanges: true,
    });

    const [form] = Form.useForm();

    const { selectProps: categorySelectProps } = useSelect<ICategory>({
        resource: "categories",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            pageSize: 999999,
            mode: "server"
        }
    });

    const [visible, setVisible] = useState(false);
    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };

    const resetFilter = () => {
        searchFormProps.form?.resetFields()
        // console.log('Reset:', )
    };

    return (
        <List
            title={'Espositore - Importo Totale:  € ' +
                Number(parseFloat(totEspositore.toString()).toFixed(2)).toLocaleString('de', {
                    minimumFractionDigits: 2
                })}
            headerProps={{
                extra: [
                    <>
                        <Row>
                            <Col style={{ marginRight: 10}} >
                                <Popover
                                    placement="leftTop"
                                    style={{
                                        float: "right"
                                    }}
                                    content={
                                        <Form form={form} layout="horizontal" {...searchFormProps} labelCol={{ span: 8 }}
                                              wrapperCol={{ span: 16 }}>
                                            <Form.Item label="Cerca" name="description">
                                                <Input
                                                    allowClear={true}
                                                    placeholder="Cerca"
                                                    prefix={<Icons.SearchOutlined />}
                                                />
                                            </Form.Item>
                                            <Form.Item label="Categoria" name="category">
                                                <Select
                                                    {...categorySelectProps}
                                                    allowClear={true}
                                                    placeholder="Categoria"

                                                />
                                            </Form.Item>
                                            <Form.Item>
                                                <Space>
                                                    <Button htmlType="submit" type="primary" onClick={() => handleVisibleChange(false)}>
                                                        Filter
                                                    </Button>
                                                    <Button htmlType="button" type="primary" onClick={resetFilter}>
                                                        Reset
                                                    </Button>
                                                </Space>

                                            </Form.Item>
                                        </Form>}
                                    title="Custom Filter"
                                    trigger="click"
                                    open={visible}
                                    onOpenChange={handleVisibleChange}
                                >
                                    <Button type="primary" style={{marginBottom: 15}} >Filtro</Button>
                                </Popover>
                            </Col>
                        </Row>
                    </>
                ],
            }}
        >
            <Table size="small" {...tableProps}
                   key="id"
                   rowKey="id"
                   sticky={false}
                   scroll={{y: window.innerHeight - 293 + 'px'}}
                   pagination={false}
                   rowClassName={(record, index) => (record.isRadiato ? "red" : "")}
                   footer={data =>
                       detailCategoryEspositore.map((x: any) => {
                           return (<>
                               <TagField value={x?.category + ': ' + x.qta} color={'gold'}/>
                               <span style={{
                                   marginRight: 15,
                                   color: 'coral'
                               }}>{Number(parseFloat(x?.tot).toFixed(2)).toLocaleString('de', {
                                   minimumFractionDigits: 2
                               })} €</span>
                           </>)
                       })
                   }
            >
                <Table.Column
                    dataIndex="product"
                    key="product.codice"
                    title="Codice"
                    render={(value) => value?.codice}
                />
                <Table.Column
                    dataIndex="product"
                    key="product"
                    title={translate('pages.stock.product', 'Descrizione')}
                    render={(value) => value?.description}
                />
                <Table.Column<IStock>
                    dataIndex="scansiaPz"
                    key="scansiaPz"
                    title={translate('pages.stock.scansiaPz', 'Pezzi')}
                    render={(value, data: any) => {
                        return value;
                    }}
                />
                <Table.Column
                    dataIndex="product"
                    key="product"
                    title={translate('pages.stock.price', 'Prezzo')}
                    render={(value) => (
                        Number(parseFloat(value?.price).toFixed(2)).toLocaleString('de', {
                            minimumFractionDigits: 2
                        }) + " €"
                    )}
                />
                <Table.Column
                    dataIndex="totale"
                    key="totale"
                    title={translate('pages.stock.totale', 'Totale')}
                    render={(value, record: any) => {
                        const totalPrice = record?.product?.price * record?.scansiaPz;
                        return Number(parseFloat(totalPrice.toString()).toFixed(2)).toLocaleString('de', {
                            minimumFractionDigits: 2
                        }) + " €"
                    }
                    }
                />
                <Table.Column<{ id: string }>
                    title={translate('pages.users.action', 'Azioni')}
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                onClick={() => editModalShow(record.id)}
                            />
                        </Space>
                    )}
                />
            </Table>
            <Modal {...editModalProps} footer={<></>}
                   title={'Dettaglio'}
            >
                <Form {...editFormProps} layout="vertical">
                    <Row gutter={24}>
                        <Col span={16}>
                            <Form.Item
                                label="Prodotto"
                                name={["product","description"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={6} hidden={true}>
                            <Form.Item
                                label="Qta"
                                name="qta"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Magazzino"
                                name="giacenza"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Espositore"
                                name="scansiaPz"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6} hidden={true}>
                            <Form.Item
                                label="Scansia N.ro"
                                name="scansiaNr"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Min. Espositore"
                                name="scansiaStock"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Stock Min. Riordino Kg/Conf"
                                name="stockMinKg"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item
                                label="Aggio %"
                                name="aggio"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Pezzi per Confezione"
                                name="nrPezziStecca"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Peso Fisc. Kg/€ Cf."
                                name="pesoFiscaleConf"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </List>

    );
};
