import io from 'socket.io-client';
import {SOCKET_URL} from '../constants';
import React from 'react';

export const socket = io(SOCKET_URL, {
    'reconnection': true,
    'reconnectionDelay': 500,
    'reconnectionAttempts': 10
});
export const SocketContext = React.createContext<any>([]);

// export const SocketProvider = (props: any) => {
//
//     return <SocketContext.Provider value={socket} {...props} />;
// }
