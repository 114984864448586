import {
    IResourceComponentsProps,
    useCreate,
    useCustom,
    useDelete,
    useGetIdentity,
    useList,
    useMany,
    useNotification,
    useTranslate,
    useUpdate,
} from "@refinedev/core";

import { DateField, DeleteButton, EditButton, List, TagField, useSelect, useTable } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
    Button,
    Col,
    Input, InputRef, Modal,
    Row,
    Select,
    SelectProps,
    Space,
    Table,
    Tag,
    Typography,
} from 'antd';

import React, {createRef, useContext, useEffect, useRef, useState} from 'react';
import {IProduct, IRestock, ISaleMovement, IStock} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import {SocketContext} from '../../contexts/socket';
import {DeviceContext} from '../../contexts/DeviceContext';
import {TurnContext} from '../../contexts/TurnContext';
import dayjs from 'dayjs';
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';


const {Title} = Typography;
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
export const VenditaList: React.FC<IResourceComponentsProps> = () => {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.locale('it') // use locale globally
    dayjs().locale('it').format() // use locale in a specific instance
    const translate = useTranslate();
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const [userPermission, setUserPermission] = value2;
    const [turn] = useContext(TurnContext);
    const [device] = useContext(DeviceContext);
    const socket = useContext(SocketContext);
    const {open, close} = useNotification();
    const {data: userIdentity} = useGetIdentity<any>();
    const [tot, setTot] = useState<number>(0);
    const [productId, setProductId] = useState<number>();
    const [detailCategory, setDetailCategory] = useState<any>([]);

    // SEARCH SIA PER DESCRIZIONE CHE PER BARCODE
    const [textSearch, setTextSearch] = useState<any>();
    const [isBarcode, setIsBarcode] = useState(false);
    const {selectProps, queryResult, defaultValueQueryResult} = useSelect<IStock>({
        resource: 'stocks',
        meta: {
            populate: '*'
        },

        queryOptions: {
            enabled: true
        },

        filters: [
            {
                operator: 'or',
                value: [
                    {
                        field: 'product][barcodes][code]',
                        operator: 'eq',
                        value: textSearch
                    },
                    {
                        field: 'product][barcode_packages][code]',
                        operator: 'eq',
                        value: textSearch
                    },
                    {
                        field: 'product][description]',
                        operator: 'contains',
                        value: textSearch
                    }
                ]
            },
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ],

        pagination: {
            mode: "server"
        }
    });

    const {tableProps, tableQueryResult} = useTable<ISaleMovement>({
        resource: 'sale-movements',

        queryOptions: {
            enabled: true
        },

        meta: {
            populate: 'device, product, product.category, seller, turn, users'
        },

        pagination: {
            pageSize: 1000
        },

        filters: {
            permanent: [
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id
                },
                {
                    field: 'isClosed',
                    operator: 'eq',
                    value: false
                },
                {
                    field: 'isDeleted',
                    operator: 'eq',
                    value: false
                },
                {
                    field: 'users][id]',
                    operator: (userPermission === 'operator' ? 'gte' : 'eq'),
                    value: (userPermission === 'operator' ? 0 : userIdentity?.id)
                },
            ]
        },

        sorters: {
            permanent: [
                {
                    field: 'createdAt',
                    order: 'desc'
                },
                {
                    field: 'product][category][id]',
                    order: 'asc'
                },
                {
                    field: 'id',
                    order: 'desc'
                },
            ]
        }
    });

    const handleSearch = (newValue: string) => {
        setTextSearch(newValue);
    };

    const addSaleMovement = useCreate<ISaleMovement>();
    const updateStock = useUpdate<IStock>();
    const updateRestock = useUpdate<IRestock>();
    const restockList = useList<IRestock>({
        resource: 'restocks',

        queryOptions: {
            enabled: false
        },

        meta: {
            populate: 'product, seller, stock'
        },

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            },
            {
                field: 'product][id]',
                operator: 'eq',
                value: productId
            },
            {
                field: 'isRiassortito',
                operator: 'eq',
                value: false
            },
        ]
    })

    const product = useList({
        resource: 'stocks',

        meta: {
            populate: 'product, seller'
        },

        queryOptions: {
            enabled: false
        },

        filters: [
            {
                field: 'product][id]',
                operator: 'eq',
                value: productId
            },
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ]
    })

    // console.log('Dispositivo: ', device);
    const handleSelect = (value: any) => {
        // console.log(value);
        // console.log(device)
        setIsBarcode(false);
        if (turn && turn.dataIn !== '') {
            // if (device) {
                // console.log(value);
                // console.log(queryResult);
                // Controllo quantità prodotto
                const c: any = queryResult.data?.data.findIndex(x => x.product.id === value);
                // console.log(c);
                if (c !== -1) {
                    let message = '';
                    if (queryResult.data && queryResult.data?.data[c]) {
                        // console.log(c);
                        // console.log(queryResult.data?.data[c].product);
                        switch (true) {
                            case ((queryResult.data?.data[c].scansiaPz - 1) <= queryResult.data?.data[c].scansiaStock):
                                message = 'Devi Effettuare il Riassortimento!';
                                break;
                            // case (queryResult.data?.data[c].scansiaPz % 10 === 0):
                            //     message = 'Attenzione hai Venduto altri 10 Pacchetti! Consiglio il Riassortimento';
                            //     break;
                            default:
                                message = '';
                                break;
                        }
                        if (message) {
                            open?.({
                                type: "success",
                                message: message,
                                description: 'Informazione',
                            });
                        }
                        setProductId(queryResult.data?.data[c]?.product?.id);
                        addSaleMovement.mutateAsync({
                            resource: 'sale-movements',
                            successNotification: false,
                            values: {
                                product: queryResult.data?.data[c]?.product?.id,
                                seller: store.id,
                                users: userIdentity.id,
                                turn: turn.id,
                                description: queryResult.data?.data[c]?.product?.description,
                                qta: 1,
                                price: queryResult.data?.data[c]?.product?.price,
                                isClosed: false,

                            }
                        }).then(r => {
                            // Trovo Prodotto in Stock
                            product.refetch().then((res: any) => {
                                // console.log(res);
                                if (res && res.data && res.data.data.length > 0) {
                                    // console.log(res.data.data[0].id);
                                    updateStock.mutateAsync({
                                        resource: 'stocks',
                                        successNotification: false,
                                        id: res.data.data[0].id,
                                        values: {
                                            scansiaPz: res.data.data[0].scansiaPz - 1
                                        }
                                    }).then(r => {
                                        // Cerco Articolo in Riassortimento
                                        restockList.refetch().then(resStock => {
                                            // console.log(resStock);
                                            let qtaMancaStock;
                                            if (resStock && resStock.data && resStock.data.data.length > 0) {
                                                console.log('scansiaStock: ', res.data.data[0].scansiaStock);
                                                console.log('scansiaPz: ', res.data.data[0].scansiaPz -1);
                                                console.log('qtaRiassortimento: ', resStock.data.data[0].qtaRiassortimento);
                                                console.log('nrPezziStecca: ', res.data.data[0].nrPezziStecca);
                                                const mancaStock = (res.data.data[0].scansiaPz - 1);
                                                // const mancaStock = ((res.data.data[0].scansiaPz - 1)  - res.data.data[0].scansiaStock) % res.data.data[0].nrPezziStecca;
                                                const multiplo10 = ((res.data.data[0].scansiaPz - 1) % res.data.data[0].nrPezziStecca);
                                                // const mancaStock = (res.data.data[0].scansiaStock - ((res.data.data[0].scansiaPz -1) + resStock.data.data[0].qtaRiassortimento) % res.data.data[0].nrPezziStecca);
                                                if (multiplo10 === 0) {
                                                    open?.({
                                                        type: "success",
                                                        message: 'Attenzione hai Venduto altri ' + res.data.data[0].nrPezziStecca + ' Pacchetti! Consiglio il Riassortimento',
                                                        description: 'Informazione',
                                                    });
                                                }
                                                console.log('MancaStock: ', mancaStock);
                                                if (mancaStock === res.data.data[0].scansiaStock) {
                                                    // qtaMancaStock= Math.trunc((res.data.data[0].scansiaStock - (res.data.data[0].scansiaPz -1)) / res.data.data[0].nrPezziStecca) + 1;
                                                    open?.({
                                                        type: "success",
                                                        message: 'Attenzione stai andando sottoscorta ' + res.data.data[0].nrPezziStecca + ' Pacchetti! Consiglio il Riassortimento',
                                                        description: 'Informazione',
                                                    });
                                                    // Eseguo Update riga Riassortimento
                                                    updateRestock.mutateAsync({
                                                        resource: 'restocks',
                                                        successNotification: false,
                                                        id: resStock.data.data[0]?.id,
                                                        values: {
                                                            isRiassortito: false,
                                                            qtaRiassortimento: 1,
                                                            dataSottoscorta: new Date()
                                                        }
                                                    }).then(updateRes => {
                                                        // console.log(updateRes);
                                                    })
                                                }
                                                setTextSearch('');
                                                setBarcode('');
                                            } else {
                                                open?.({
                                                    type: 'error',
                                                    message: 'Riga ReStock non Trovato: ' + queryResult.data?.data[c]?.product?.id,
                                                    description: 'Attenzione!',
                                                });
                                            }
                                        })

                                    })
                                }
                            });
                        })
                    } else {
                        open?.({
                            type: 'error',
                            message: 'Articolo non presente in Scansia \nQuantità disponibile in Magazzino: ' + queryResult.data?.data[0].giacenza + ' Stecca',
                            description: 'Attenzione!',
                        });
                    }
                } else {
                    open?.({
                        type: 'error',
                        message: 'Articolo non Trovato: ' + value,
                        description: 'Attenzione!',
                    });
                }
            // } else {
            //     open?.({
            //         type: 'error',
            //         message: 'Devi Selezionare il Dispositivo ESP...',
            //         description: 'Dispositivo Non Trovato',
            //     });
            // }
        } else {
            open?.({
                type: 'error',
                message: 'Ricordati di Aprire il Turno...',
                description: 'Attenzione',
            });
        }
    };

    const deleteSaleMovement = useUpdate<ISaleMovement>();

    const { confirm } = Modal;
    const inputRef: any = useRef<InputRef>(null);
    const showConfirm = (data: any) => {
        confirm({
            title: 'Per effettuare la variazione è richiesta una password...',
            icon: <Icons.ExclamationCircleFilled />,
            content: <>
                <Input type={'password'} ref={inputRef} placeholder={'Password...'}></Input>
            </>,
            onOk() {
                if (inputRef.current.input.value === 'Espo2023') {
                    localStorage.setItem('passVendite', inputRef.current.input.value);
                    deleteMovement(data);
                };
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handleDelete = (data: any) => {
        if (localStorage.getItem('passVendite') === 'Espo2023') {
            // AZIONE
            deleteMovement(data);
        } else {
            showConfirm(data)
        }
    }
    const deleteMovement = (data: any) => {
        // console.log('Cancello Movimento: ', data);
        setProductId(data.product.id);
        // console.log('Prodotto Movimento: ', data.product.id)
        deleteSaleMovement.mutateAsync({
            resource: 'sale-movements',
            successNotification: false,
            id: data.id,
            values: {
                isDeleted: true,
            }
        }).then((res: any) => {
            // Trovo Prodotto in Stock
            product.refetch().then((res: any) => {
                // console.log(res);
                if (res && res.data.data && res.data.data.length > 0) {
                    // console.log(res.data.data[0].id);
                    // Aggiorno Qta Stock
                    updateStock.mutateAsync({
                        successNotification: false,
                        resource: 'stocks',
                        id: res.data.data[0].id,
                        values: {
                            scansiaPz: res.data.data[0].scansiaPz + 1
                        }
                    }).then(resUpd => {
                        // Cerco Articolo in Riassortimento
                        console.log('Cerco Articolo in Riassortimento');
                        restockList.refetch().then(resStock => {
                            console.log('Restock: ', resStock);
                            if (resStock && resStock.data && resStock.data.data.length > 0) {
                                let qtaMancaStock;
                                const mancaStock = (res.data.data[0].scansiaPz + 1);
                                console.log(mancaStock);
                                // const mancaStock = ((res.data.data[0].scansiaPz - 1)  - res.data.data[0].scansiaStock) % res.data.data[0].nrPezziStecca;
                                // const mancaStock = (res.data.data[0].scansiaStock - (res.data.data[0].scansiaPz + resStock.data.data[0].qtaRiassortimento) % res.data.data[0].nrPezziStecca);
                                // console.log('MancaStock: ', mancaStock);
                                if (mancaStock > res.data.data[0].scansiaStock) {
                                    // qtaMancaStock= Math.trunc((res.data.data[0].scansiaStock - res.data.data[0].scansiaPz) / res.data.data[0].nrPezziStecca) + 1;
                                    // Eseguo Update riga Riassortimento
                                    updateRestock.mutateAsync({
                                        resource: 'restocks',
                                        successNotification: false,
                                        id: resStock.data.data[0]?.id,
                                        values: {
                                            isRiassortito: false,
                                            qtaRiassortimento: 0,
                                            dataSottoscorta: null
                                        }
                                    }).then(updateRes => {
                                        // console.log(updateRes);
                                    })
                                }
                            } else {
                                console.log('Error');
                            }
                        });
                        setTimeout(() => {
                            setBarcode('');
                            inputElement?.current?.focus();
                        }, 500)
                        open?.({
                            type: 'success',
                            message: 'Articolo Tornato nell\'espositore',
                            description: 'Info!',
                        });
                        localStorage.removeItem('passVendite');
                    })
                }
            });
        })

    }
    const updateTot = () => {
        if (tableQueryResult && tableQueryResult.data && tableQueryResult.data?.data.length > 0) {
            setTot(parseFloat(Number(tableQueryResult.data?.data.map(item => item.product.price).reduce((prev, next) => prev + next)).toFixed(2)));
        } else {
            setTot(0)
        }
        // GROUP BY CATEGORY
        const tempGroup: any = tableQueryResult.data?.data.reduce((r: any, a: any) => {
            // console.log("a", a);
            // console.log("r", r);
            r[a.product.category.description] = [...(r[a.product.category.description] || []), a];
            return r;
        }, {})

        let priceCategory = [];

        for (let g in tempGroup) {
            priceCategory.push({
                qta: tempGroup[g].length,
                category: g,
                tot: parseFloat(Number(tempGroup[g].map((item: any) => item.product.price).reduce((prev: number, next: number) => prev + next)).toFixed(2))
            })
        }
        // console.log(priceCategory)
        setDetailCategory(priceCategory);
    }

    useEffect(() => {
        updateTot();
    }, [tableQueryResult.isFetching])

    useEffect(() => {
        socket.on('sendSaleMovement', (data: any, error: any) => {
            tableQueryResult.refetch();
            // console.log('Ricevuto Aggiornamento Cart: ', data);
        });
        socket.on('sendBarcodeNotFound', (data: any, error: any) => {
            // console.log('Ricevuto Barcode Not Found: ', data);
            open?.({
                type: 'error',
                message: 'Barcode non Trovato...',
                description: data,
            });
        });
        return () => {
            socket.off('sendSaleMovement');
            socket.off('sendBarcodeNotFound');
        };
    }, []);

    const inputElement: any = useRef(null) || '';
    const handleFocus = (event: any) => {
        event.target.select();
    };

    useEffect(() => {
        setTimeout(() => {
            inputElement?.current?.focus();
        }, 300)
    }, []);

    const barcodeSearch = (e: any) => {
        // console.log('Barcode:', e);
        setIsBarcode(true);
        setTextSearch(e);
    };

    const [barcode, setBarcode] = useState('');
    useEffect(() => {
        if (isBarcode) {
            // console.log(queryResult);
            setTimeout(() => {
                if (queryResult && queryResult.data && queryResult.data.data.length > 0) {
                    inputElement?.current?.select();
                    handleSelect(queryResult.data?.data[0]?.product?.id);
                } else {
                    setIsBarcode(false);
                    setTextSearch('');
                    // inputElement?.current?.select();
                    setBarcode('');
                    open?.({
                        type: 'error',
                        message: 'Articolo non Trovato',
                        description: 'Attenzione!',
                    });
                }
            }, 300)
        }
    }, [queryResult.data?.data?.length])

    // console.log(isBarcode);
    const allOptions = [
        ...(queryResult.data?.data || []),
        ...(defaultValueQueryResult.data?.data || [])
    ];

    // console.log(tableQueryResult.isSuccess, tableQueryResult.isFetched, tableQueryResult.isFetching, tableQueryResult.isRefetching)
    return (
        <List
            title={translate('pages.vendita.title', (userPermission === 'operator' ? 'Vendita' : (!turn?.dataIn || turn?.dataOut) ? 'Vendita -> Ricordati di Aprire il Turno' : 'Vendita'))}
        >
            <div hidden={(userPermission === 'operator' ? false : (!turn?.dataIn || turn?.dataOut))}>
                <Row gutter={24}>
                    <Col span={10}>
                        <Input
                            style={{
                                width: '100%'
                            }}
                            placeholder={'Barcode...'}
                            ref={inputElement}
                            onFocus={(e) => handleFocus(e)}
                            onPressEnter={(e: any) => barcodeSearch(e.target.value)}
                            onChange={(e) => setBarcode(e.target.value)}
                            allowClear={true}
                            value={barcode}
                        />
                    </Col>
                    <Col span={10}>
                        <Select
                            style={{
                                width: '100%'
                            }}

                            placeholder={'Ricerca Manuale...'}
                            {...selectProps}
                            options={allOptions.map((p) => ({
                                label: `${p.product.description}`,
                                value: p.product.id
                            }))}
                            onSearch={handleSearch}
                            // onKeyDown={(e) => handleSearchBarcode(e)}
                            allowClear={true}
                            onSelect={(e) => handleSelect(e)}
                        />
                    </Col>
                    <Col span={4}>
                        <Input key={Math.random()} value={'Totale: € ' + tot}
                               style={{textAlign: 'right', color: 'green'}}/>
                    </Col>
                </Row>
                <br/>
                <Row gutter={24}>
                    <Col span={24}>
                        <Table size={'small'} {...tableProps} pagination={false} key={'id'}
                               scroll={{y: window.innerHeight - 293 + 'px'}}
                               footer={data =>
                                detailCategory.map((x: any) => {
                                    return (<>
                                        <TagField value={x?.category + ': ' + x.qta} color={'gold'}/>
                                            <span style={{
                                                marginRight: 15,
                                                color: 'coral'
                                            }}>{Number(parseFloat(x?.tot).toFixed(2)).toLocaleString('de', {
                                                minimumFractionDigits: 2
                                            })} €</span>
                                        </>)
                                    })
                                }
                               rowClassName={(record, index) => (index === 0 && !tableQueryResult.isRefetching  ? "blu" : "")}
                            >
                            <Table.Column
                                dataIndex="description"
                                key="description"
                                title="Descrizione"
                            />
                            <Table.Column
                                dataIndex="createdAt"
                                key="createdAt"
                                title="Data"
                                render={(value) => (
                                    <DateField value={dayjs(value)} format={'DD/MM/YYYY HH:mm:ss'}/>
                                )}
                            />
                            <Table.Column
                                dataIndex="product"
                                key="product"
                                title="Price"
                                render={(value) => (
                                    Number(parseFloat(value?.price).toFixed(2)).toLocaleString('de', {
                                        minimumFractionDigits: 2
                                    }) + ' €'
                                )}
                            />
                            <Table.Column<{ id: string }>
                                title={translate('pages.venditas.action', 'Actions')}
                                dataIndex="actions"
                                key="actions"
                                render={(_, record) => (
                                    <Space>
                                        <Button
                                            type={'primary'}
                                            size="small"
                                            htmlType={'button'}
                                            onClick={() => handleDelete(record)}
                                        >Delete</Button>
                                    </Space>
                                )}
                            />
                        </Table>
                    </Col>
                </Row>
            </div>
        </List>
    );
};
