import {
    CrudFilters,
    getDefaultFilter,
    IResourceComponentsProps,
    useExport,
    useList,
    useTranslate
} from '@refinedev/core';

import {
    BooleanField,
    CreateButton,
    DeleteButton,
    EditButton,
    ExportButton, getDefaultSortOrder,
    List,
    TagField,
    useSelect,
    useTable,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Button, Checkbox, Col, Form, Input, Popover, Row, Select, Space, Table} from 'antd';
import React, {useState} from 'react';
import {ICategory, IProduct, ISeller} from '../../interfaces';
import dayjs from 'dayjs';
import {CSVLink} from 'react-csv';

export const ProductList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {tableProps, searchFormProps, sorter, tableQueryResult, filters} = useTable<IProduct>({
        resource: "products",
        syncWithLocation: false,
        meta: {populate: 'barcodes, category, barcode_packages'},

        onSearch: (params: any) => {
            console.log(params)
            const productFilters: CrudFilters = [];
            localStorage.setItem('filters', JSON.stringify(params));
            productFilters.push(
                {
                    operator: "or",
                    value: [
                        {
                            field: "barcodes][code]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "barcode_packages][code]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "description",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "codice",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        }
                    ]
                },
                {
                    field: "category][id]",
                    operator: "contains",
                    value: params?.category,
                },
            );

            return productFilters;
        }
    });

    const {data} = useList<any>({
        resource: 'custom/stampaArticoli'
    })
    const { selectProps: categorySelectProps } = useSelect<ICategory>({
        resource: "categories",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            mode: "server"
        }
    });

    const {triggerExport, isLoading: exportLoading} = useExport<IProduct>();

    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [dataExp, setDataExp] = useState<any>([]);
    const change = (e: any, record: any, index: number) => {
        if (data && data.data && data.data?.length > 0) {
            let gIndex = 0;
            for (let d of data.data) {
                // console.log(activeFilter)
                    console.log(d);
                    setDataExp((current: any) => [...current, {
                        codice: d.codice,
                        description: d.description,
                        note: d.note,
                        price: d.price ? Number(d.price).toFixed(2): '',
                        priceKg: d.price_kg ? Number(d.price_kg).toFixed(2) : '',
                        peso: d.weight ? d.weight.toString() : '',
                        barcode: d.barcode ? '\'' + d.barcode + '\'' : '',
                        barcodes_package: d.barcode_package ? '\'' + d.barcode_package + '\'' : ''
                    }]);
            }
        }
    }
    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };

    const resetFilter = () => {
        form.resetFields(['fields'])
        console.log('Reset:', )
    };

    return (
            <List
                title={translate('pages.product.title', 'Product')}
                headerProps={{
                    extra: [
                        <>
                        <Row>
                            <Col style={{ marginRight: 10}}>
                                <Popover
                                    placement="leftTop"
                                    style={{
                                        float: "right"
                                    }}
                                    content={
                                    <Form layout="horizontal"
                                          onLoad={() => {
                                              searchFormProps.form?.submit();
                                          }}
                                          onValuesChange={() => {
                                              searchFormProps.form?.submit();
                                          }}
                                          initialValues={{
                                              description: getDefaultFilter("description", filters, "contains"),
                                              category: getDefaultFilter("category.id", filters, "in"),
                                          }}
                                          {...searchFormProps}
                                          labelCol={{ span: 8 }}
                                          wrapperCol={{ span: 16 }}
                                    >
                                        <Form.Item
                                            label="Cerca"
                                            name="description"
                                        >
                                            <Input
                                                allowClear={true}
                                                placeholder="Cerca"
                                                prefix={<Icons.SearchOutlined />}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Categoria"
                                            name="category"
                                        >
                                            <Select
                                                {...categorySelectProps}
                                                allowClear={true}
                                                placeholder="Categoria"

                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <Space>
                                                <Button htmlType="submit" type="primary" onClick={() => handleVisibleChange(false)}>
                                                    Filter
                                                </Button>
                                                <Button htmlType="button" type="primary" onClick={resetFilter}>
                                                    Reset
                                                </Button>
                                            </Space>

                                        </Form.Item>
                                    </Form>}
                                    title="Custom Filter"
                                    trigger="click"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange}
                                >
                                    <Button type="primary" style={{marginBottom: 15}}>Filter</Button>
                                </Popover>
                            </Col>
                            <Col>
                                <CreateButton/>,
                                <CSVLink
                                    separator={';'}
                                    filename={'Export_Articoli.csv'}
                                    data={dataExp}
                                    asyncOnClick={true}
                                    onClick={() => (dataExp && dataExp.length < 0) ? null : change(null, null, -1)}
                                >
                                    <Button type={'primary'}>Export</Button>
                                </CSVLink>
                            </Col>
                        </Row>
                        </>
                    ],
                }}
            >
                <Table size="small" {...tableProps} rowKey="id"
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   // console.log("product", record);
                               },
                           };
                       }}
                       sticky={false}
                       pagination={{
                           ...tableProps.pagination,
                           showSizeChanger: true,
                       }}>
                    {/*<Table.Column dataIndex="id" key="id" title="ID"/>*/}
                    <Table.Column dataIndex="codice" key="codice" title="AMS"/>
                    <Table.Column
                        dataIndex="description"
                        key="description"
                        title={translate('pages.product.description', 'Description')}
                    />
                    <Table.Column
                        dataIndex="price"
                        key="price"
                        title={translate('pages.product.price', 'Price')}
                        render={(value) => Number(parseFloat(value).toFixed(2)).toLocaleString('de', {
                                minimumFractionDigits: 2
                            }) + " €" }
                    />
                    <Table.Column
                        dataIndex="category"
                        key="category"
                        title={translate('pages.product.category', 'Categoria')}
                        render={(value) => <TagField value={value?.description} />}
                    />
                    <Table.Column
                        dataIndex="barcodes"
                        key="barcodes"
                        title={translate('pages.product.barcodes', 'Barcode')}
                        render={(value) => {
                            return (value?.map((x: any) =>
                                <TagField value={x.code} />
                            ))
                        }}
                    />
                    <Table.Column
                        dataIndex="barcode_packages"
                        key="barcode_packages"
                        title={translate('pages.product.barcode_packages', 'Barcode Package')}
                        render={(value) => {
                            return (value?.map((x: any) =>
                                <TagField value={x.code} />
                            ))
                        }}
                    />
                    <Table.Column<{ id: string }>
                        title={translate('pages.users.action', 'Action')}
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}

                                />
                                <DeleteButton
                                    mutationMode={'undoable'}
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Space>
                        )}
                    />

                </Table>
            </List>
    );
};
