import { IResourceComponentsProps, useList, useTranslate } from "@refinedev/core";
import {List, useTable} from '@refinedev/antd';
import {Button, Col, Input, Row, Table} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import {ISaleMovement} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import dayjs from 'dayjs';
import {CSVLink} from 'react-csv';
export const StockRiordinoScientifico: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {value} = useContext(StoreContext);
    const [store] = value;
    const d = new Date();
    const d1 = new Date();
    d.setHours(0,0,0,0);
    d1.setHours(0,0,0,0);
    const d10 = d1.setDate(d1.getDate() - 10);
    const {tableQueryResult} = useTable<ISaleMovement>({
        resource: "sale-movements",

        queryOptions: {
            enabled: true,
        },

        meta: {populate: 'seller, product, product.category'},

        pagination: {
            pageSize: 999999999,
            mode: "server"
        },

        filters: {
            permanent: [
                {
                    field: "seller.id",
                    operator: "eq",
                    value: store.id,
                },
                {
                    field: "createdAt",
                    operator: "between",
                    value: [dayjs(d10).format('YYYY-MM-DD'), dayjs(d).format('YYYY-MM-DD')]
                },
            ]
        },

        sorters: {
            permanent: [
                {
                    field: 'product.description',
                    order: 'asc'
                }
            ]
        }
    });

    const {data} = useList({
        resource: 'stocks',

        meta: {
            populate: 'product, seller'
        },

        queryOptions: {
            enabled: true
        },

        pagination: {
          pageSize: 99999999,
            mode: 'server'
        },

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ]
    });

    const {refetch} = useList({
        resource: 'ware-house-positions',

        meta: {
            populate: 'stock, stock.product, seller'
        },

        queryOptions: {
            enabled: false
        },

        pagination: {
            pageSize: 99999999,
            mode: 'server'
        },

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            },
            {
                field: 'dataOut',
                operator: 'null',
                value: undefined
            }
        ],
        sorters: [
            {
                field: 'position',
                order: 'asc'
            }
        ]
    });

    const [dataArray, setDataArray] = useState<any>([]);

    // SOMA QTA VENDUTE ULTIMI 10GG / 10 * 7gg / pzConf - giacenza

    useEffect(() => {
        refetch().then( (result: any) => {
            // let q = 0;
            const tempGroup: any = result.data?.data.reduce((r: any, a: any) => {
                r[a.stock.product.id] = [...(r[a.stock.product.id] || []), a];
                return r;
            }, {})
            let pos: any = [];
            for (let g in tempGroup) {
                pos.push({
                    qta: tempGroup[g].length,
                    id: Number(g)
                })
            }
            if (tableQueryResult && tableQueryResult.data?.data && tableQueryResult.data.data.length > 0) {
                const result: any = [];
                let index = 0;
                let gIndex = 0;
                tableQueryResult.data.data.reduce( (res: any, value: any) => {
                    if (data && data.data) {
                        index = data.data.findIndex((x: any) => x.product.id === value.product.id);
                        gIndex = pos.findIndex((x: any) => x.id === value.product.id);
                    }
                    if (!res[value.product.id]) {
                        res[value.product.id] = {
                            id: value.product.id,
                            codice: value.product.codice,
                            description: value.product.description,
                            price: value.product.price,
                            priceKg: value.product.priceKg,
                            giacenza: ((gIndex !== -1) ? pos[gIndex].qta: 0),
                            nrPezziStecca: data?.data[index]?.nrPezziStecca,
                            stockMinKg: data?.data[index]?.stockMinKg,
                            pesoFiscaleConf: data?.data[index]?.pesoFiscaleConf,
                            isGeneric: value.product.category.isGeneric,
                            isTabacco: value.product.category.isTabacco,
                            qta: 0 };
                        result.push(res[value.product.id])
                    }
                    res[value.product.id].qta += value.qta;
                    return res;
                }, {});
                result.map((r: any) => {
                    // console.log(r);
                    const qtaR = Math.max(Math.ceil(((r['qta']/10)*7)/r['nrPezziStecca']) - r['giacenza'], 0);
                    const mlp = (r['isGeneric']) ? 1 : r['pesoFiscaleConf'];
                    // console.log(r['isGeneric'], qtaR, mlp, r['pesoFiscaleConf'])
                    r.qtaRiordinare = Number(qtaR * mlp).toFixed(2);
                    r.totale = (r['isGeneric']) ? Number(r['pesoFiscaleConf']) * r.qtaRiordinare : Number(r['priceKg']) / (1 / r.qtaRiordinare);
                })
                setDataArray(result)
            }
        })
    }, [tableQueryResult.data?.data])

    const [dataExp, setDataExp] = useState<any>([]);
    // const [category, setCategory] = useState('');

    const change = (e: any, record: any, index: number) => {
        //console.log(record);
        setDataExp([]);
        if (index === -1) {
            if (record && record.length > 0) {
                for (let d of record) {
                    if (d.qtaRiordinare > 0) {
                        setDataExp((current: any) => [...current, {
                            codice: d.codice,
                            qta: d.qtaRiordinare,
                            importo: d.totale
                        }]);
                    }

                }
            }
        } else {
            // let gIndex = 0;
            if (dataArray && dataArray.length > 0) {
                // console.log('Change: ', index, e.target.value, record.id);
                const indexF = dataArray.findIndex((x: any) => x.id === record.id);
                // console.log(indexF);
                // console.log(dataArray);
                dataArray[indexF].qtaRiordinare = Number(e.target.value);
                dataArray[indexF].totale = (dataArray[indexF]['isGeneric']) ? Number(dataArray[indexF]['pesoFiscaleConf']) * Number(e.target.value) : Number(dataArray[indexF]['priceKg']) / (1 / Number(e.target.value));
                // console.log(dataArray[indexF]);
                setDataArray([...dataArray]);
                // console.log(tempData);
                let exp = [];
                for (let d of dataArray) {
                    // const consigliato = (d.product.category.isGeneric) ? Math.abs(d.giacenza - d.stockMinKg) : ((d.giacenza * d.pesoFiscaleConf) >= d.stockMinKg ? 0 : Math.abs((d.giacenza * d.pesoFiscaleConf) - d.stockMinKg ));
                    // console.log(consigliato);
                    // const tempC = d.consigliato > 0 ? d.consigliato : consigliato;
                    // const tempC = d.qtaRiordinare;
                    if (d.qtaRiordinare > 0) {
                        exp.push({
                            codice: d.codice,
                            qta: Number(d.qtaRiordinare).toFixed(2),
                            importo: parseFloat(d.totale).toLocaleString('de', {
                                minimumFractionDigits: 2
                            })
                        });
                    }
                }
                setDataExp(exp);
            }
        }

        }
    return (
        <>
                <List title={'Riordino Scientifico'}>
                    <Table
                        size="small" dataSource={dataArray}
                        loading={dataArray && dataArray.length === 0}
                        key="id"
                        rowKey="id"
                        sticky={false}
                        scroll={{y: window.innerHeight - 293 + 'px'}}
                        pagination={false}
                        footer={(value) => {
                            const tot = (value && value.length > 0) ? value?.map((item: any) => item?.totale).reduce((prev, next) => prev + next).toFixed(2) : 0;
                            return <Row gutter={24}>
                                <Col span={12}>
                                    <CSVLink
                                        separator={';'}
                                        filename={'Ordine_Scientifico_Stock_' + dayjs().format('DD_MM_YYYY') + '.csv'}
                                        data={dataExp}
                                        asyncOnClick={true}
                                        onClick={() => change(null, value, -1)}
                                    >
                                        <Button type={'primary'}>Genera Ordine</Button>
                                    </CSVLink>
                                </Col>
                                <Col span={12}>
                                    <div style={{
                                        textAlign: 'right',
                                        fontSize: 20
                                    }}>
                                        Totale: {parseFloat(tot.toLocaleString()).toLocaleString('de', {
                                        minimumFractionDigits: 2
                                    }) + ' €'}
                                    </div>

                                </Col>
                            </Row>
                        }}
                    >
                        <Table.Column
                            dataIndex="codice"
                            key="codice"
                            title="Codice"
                            responsive={['sm']}
                        />
                        <Table.Column
                            dataIndex="description"
                            key="description"
                            title={translate('pages.stock.description', 'Descrizione')}
                            responsive={['sm']}
                            filters={[
                                {
                                    text: 'Tabacchi',
                                    value: 'isTabacco',
                                },
                                {
                                    text: 'Gratta e Vinci',
                                    value: 'isGeneric',
                                }
                            ]}
                            onFilter={(value: any, record: any) => {
                                // setCategory(value);
                                return record[value]
                            }}
                        />
                        <Table.Column
                            dataIndex="nrPezziStecca"
                            key="nrPezziStecca"
                            title={translate('pages.stock.nrPezziStecca', 'Pezzo Stecca/Cf')}
                        />
                        <Table.Column
                            dataIndex="qta"
                            key="qta"
                            title={translate('pages.stock.stockMinKg', 'Qta Vendute')}
                            render={(value, record: any) => (
                                value
                            )}
                        />
                        <Table.Column
                            dataIndex="qta"
                            key="qta"
                            title={translate('pages.stock.stockMinKg', 'Media Qta Vendute')}
                            render={(value, record: any) => (
                                value/10
                            )}
                        />
                        <Table.Column
                            dataIndex="qta"
                            key="qta"
                            title={translate('pages.stock.stockMinKg', 'Fabbisogno 7gg')}
                            render={(value, record: any) => (
                                Math.ceil(((value/10)*7))
                            )}
                        />
                        <Table.Column
                            dataIndex="giacenza"
                            key="giacenza"
                            title={translate('pages.stock.giacenza', 'Giacenza Attuale')}
                        />
                        <Table.Column
                            dataIndex="qta"
                            key="qta"
                            title={translate('pages.stock.qta', 'Cf/St da Riordinare')}
                            render={(value, record: any) => (
                                Math.max(Math.ceil(((value/10)*7)/record['nrPezziStecca']) - record['giacenza'], 0)
                            )}
                        />
                        <Table.Column
                            dataIndex="qtaRiordinare"
                            key="qtaRiordinare"
                            title={translate('pages.stock.stockMinKg', 'Kg/Cf da Riordinare')}
                            filters={[
                                {
                                    text: 'Tutto',
                                    value: '-1',
                                },
                                {
                                    text: 'Qta > 0',
                                    value: '0',
                                }
                            ]}
                            onFilter={(value: any, record: any) => {
                                // const qtaR = Math.max(Math.ceil(((record['qta']/10)*7)/record['nrPezziStecca']) - record['giacenza'], 0);
                                // const mlp = (record['isGeneric']) ? 1 : record['pesoFiscaleConf'];
                                // return (qtaR * mlp) > Number(value)
                                return record.qtaRiordinare > Number(value)
                            }}
                            render={(value, record: any, index: number) => {
                                // const qtaR = Math.max(Math.ceil(((value/10)*7)/record['nrPezziStecca']) - record['giacenza'], 0);
                                // const mlp = (record['isGeneric']) ? 1 : record['pesoFiscaleConf'];
                                // record.qtaRiordinare = qtaR * mlp;
                                // return  (qtaR * mlp).toFixed(2)
                                return <Input style={{width: '50%'}} size={'small'} placeholder={value}
                                              onPressEnter={(e) => change(e, record, index)}/>
                            }
                            }
                        />
                        <Table.Column
                            dataIndex="priceKg"
                            key="priceKg"
                            title={translate('pages.stock.priceKg', 'Prezzo Kg/Cf')}
                            render={(value, record: any) => {
                                const mlp = (record['isGeneric']) ? Number(record['pesoFiscaleConf']) : Number(value);
                                return mlp.toLocaleString('de', {
                                    minimumFractionDigits: 2
                                });
                            }
                            }
                        />
                        <Table.Column
                            dataIndex="totale"
                            key="totale"
                            title={translate('pages.stock.priceKg', 'Totale €')}
                            render={(value, record: any) => {
                                // const qtaR = Math.max(Math.ceil(((value/10)*7)/record['nrPezziStecca']) - record['giacenza'], 0);
                                // const mlp = (record['isGeneric']) ? 1 : record['pesoFiscaleConf'];
                                // const prezzo = (record['isGeneric']) ? Number(record['pesoFiscaleConf']) * (qtaR * mlp) : Number(record['priceKg']) / (1 / (qtaR * mlp));
                                // record.totale = prezzo;
                                // return  prezzo.toLocaleString('de', {
                                //     minimumFractionDigits: 2
                                // })
                                return  parseFloat(value.toLocaleString('de', {
                                    minimumFractionDigits: 2
                                })).toFixed(2)
                            }
                            }
                        />
                    </Table>
                </List>

        </>
    );
};
