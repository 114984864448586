import { createContext, useState } from "react";

export const StoreContext = createContext<any>([]);

export const StoreProvider = (props: any) => {
    const [store, setStore] = useState(1);
    const [userPermission, setUserPermission] = useState('');

    return <StoreContext.Provider value={{value: [store, setStore], value2: [userPermission, setUserPermission]}} {...props} />;
};
